import React, { useState, useRef, useEffect, useContext } from "react";
import {
    Row,
    Table,
    Divider,
    ConfigProvider,
    Popover,
} from "antd";
import {
    QuestionCircleTwoTone,
} from "@ant-design/icons";
import { OnEmptyPopup, OnCardPopup } from "./MenuPopUp";
import { Link } from "react-router-dom";
import { useAppContext } from "../App/AppContext";
import DisplayCourseCard from "./DisplayCourseCard";
import useCourseActions from "./courseActions";
import CourseSearchAutocomplete from "./CourseSearchAutocomplete";
import { alphanumerical } from "../utils";
import FilterPanel from "./SearchFilterPane";
import { CourseListContext, CourseListProvider } from "./CourseListContext";

const tutorialTitle = <span>Tutorial</span>;

const tutorialContent = (
    <div>
        <p>Content1</p>
        <p>Content2</p>
    </div>
);

const ScheduleHeader = ({ rootRef }) => {
    const { manipulateCourseList } = useCourseActions();
    return (
        <div
            style={{
                textAlign: "left",
                fontWeight: "bold",
                fontSize: 28,
                position: 'sticky',
                top: '0px',
                zIndex: '5',
                backgroundColor: "white",
                padding: '8px'
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <div>
                    <span>Schedule</span>
                    {/* <Popover placement="rightTop" title={tutorialTitle} content={tutorialContent} trigger={"hover"}>
                        <Link to="about">
                        </Link>

                        <QuestionCircleTwoTone
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                                console.log("clicked questions");
                                
                            }}
                        />
                    </Popover> */}
                </div>
                <FilterPanel style={{
                    width: '200px'
                }}>
                    <CourseSearchAutocomplete key={"CourseSearch"} style={{
                        width: "150px"
                    }}/>
                </FilterPanel>
            </div>
            <Divider
                style={{
                    marginTop: "4px",
                    marginBottom: "0px",
                }}
            ></Divider>
        </div>
    )
}

const ScheduleInner = ({ formattedCourseData, setFormattedCourseData, queryCourses=null, setQueryCourses=null, onChange = null })=>{
    const documentRef = useRef(document);
    const rootRef = useRef(null);
    const {courseActionStep, courseActionHistory} = useContext(CourseListContext);

    const { addTerm, deleteTerm, deleteCourse, addCourse, moveCourse, swapCourse, handleCourseCardDrop, manipulateCourseList } = useCourseActions(courseActionStep, courseActionHistory);

    // This value is reserved for adapting to different screen sizes
    // const currentWidth = useRef(0);
    const [currentWidth, setCurrentWidth] = useState(0);
    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                console.log("resize left panel:", entry.contentRect.width)
                setCurrentWidth(entry.contentRect.width);
            }
        });

        if (rootRef.current) {
            resizeObserver.observe(rootRef.current);
        }

        return () => {
            if (rootRef.current) {
                resizeObserver.unobserve(rootRef.current);
            }
        };
    }, []);

    const [emptyPopupState, setEmptyPopupState] = useState({
        visible: false,
        x: 0,
        y: 0,
        record: null,
        target: null,
    });

    const [cardPopupState, setCardPopupState] = useState({
        visible: false,
        x: 0,
        y: 0,
        record: null,
        target: null,
    });

    const [cardPopupListenerCreated, setCardPopupListenerCreated] = useState(false);
    const [emptyPopupListenerCreated, setEmptyPopupListenerCreated] = useState(false);

    const reverseCourseAction = (action) => {
        let st = 0;
        let sc = 0;
        let tt = 0;
        let tc = 0;

        if (action['source']) {
            st = action["source"]["row_index"];
            sc = action["source"]["course_index"];
        }

        if (action['target']) {
            tt = action["target"]["row_index"];
            tc = action["target"]["course_index"];
        }

        if (action["operation"] === "swap") {
            swapCourse(tt, tc, st, sc, false);
        } else if (action["operation"] === "move") {
            moveCourse(tt, tc, st, sc, false);
        } else if (action["operation"] === "add") {
            deleteCourse(action['target'].row_index, action['target'].course_index, false);
        } else if (action["operation"] === "delete") {
            addCourse(action['source'].payload, action['source'].row_index, action['source'].course_index, false);
        } else if (action["operation"] === 'addTerm') {
            deleteTerm(action.payload.term_index, false);
        } else if (action["operation"] === 'deleteTerm') {
            addTerm(action.payload.term_info.term_name, action.payload.term_index, action.payload.term_info.term_courses, false);
        }
    };

    const undoCourseAction = () => {
        if (courseActionStep.current > 0) {
            reverseCourseAction(courseActionHistory.current.at(courseActionStep.current - 1));

            courseActionStep.current -= 1;
        }
    };

    const redoCourseAction = () => {
        console.log("REDO", courseActionStep.current, courseActionHistory.current);
        if (courseActionStep.current < courseActionHistory.current.length) {
            const action = courseActionHistory.current.at(courseActionStep.current);

            let st = 0;
            let sc = 0;
            let tt = 0;
            let tc = 0;

            if (action['source']) {
                st = action["source"]["row_index"];
                sc = action["source"]["course_index"];
            }

            if (action['target']) {
                tt = action["target"]["row_index"];
                tc = action["target"]["course_index"];
            }

            if (action["operation"] === "swap") {
                swapCourse(st, sc, tt, tc, false);
            } else if (action["operation"] === "move") {
                moveCourse(st, sc, tt, tc, false);
            } else if (action["operation"] === "add") {
                console.log(action)
                addCourse(action.payload, action['target'].row_index, action['target'].course_index, false);
            } else if (action["operation"] === "delete") {
                console.log(action)
                deleteCourse(action['source'].row_index, action['source'].course_index, false);
            }

            courseActionStep.current += 1;
        }
    };

    const handleKeyDown = (event) => {
        const focusedElement = document.activeElement;
        const isInputElement =
            focusedElement.tagName === "INPUT" || focusedElement.tagName === "TEXTAREA" || focusedElement.isContentEditable;

        // Currently implemented for move and swap
        // TODO: Implement for add and delete
        if (event.ctrlKey || event.metaKey) {
            if (event.key === "z") {
                if (!isInputElement) {
                    event.preventDefault();
                    undoCourseAction();
                }
            } else if (event.key === "y") {
                if (!isInputElement) {
                    event.preventDefault();
                    redoCourseAction();
                }
            }
        }
    };

    useEffect(() => {
        console.log("New action history", courseActionHistory.current, courseActionStep.current);
    }, [courseActionHistory.current]);

    console.log("Course Table Rerender")
    const courseListColumns = [
        {
            key: "term_name",
            dataIndex: "term_name",
            width: '60px',
            render: (text) => {
                return <span style={{ fontWeight: "bold" }}>{text}</span>
            },
        },
        {
            key: "term_courses",
            dataIndex: "term_courses",
            render: (_, record, __) => {
                const courses = record["term_courses"];

                if (courses === undefined) {
                    return <></>;
                }

                return (
                    <Row
                        gutter={[4, 4]}
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            marginTop: "-5px",
                            marginBottom: "-5px",
                            minHeight: '60px'
                        }}
                    >
                        {courses.map((course, index) => (
                            <DisplayCourseCard course={course} index={index} key={`${course["term"]}-${course["code"]}`} width={currentWidth / 6.8}/>
                        ))}
                    </Row>
                );
            },
        },
    ];

    const handleCourseListRightClick = (record) => ({
        onContextMenu: (event) => {
            const clickOnCard = event["target"].classList.contains("CourseCardFamily");
            event.preventDefault();
            const currentState = clickOnCard ? cardPopupState : emptyPopupState;
            const otherState = clickOnCard ? emptyPopupState : cardPopupState;

            const setterFunc = clickOnCard ? setCardPopupState : setEmptyPopupState;
            const otherSetterFunc = clickOnCard ? setEmptyPopupState : setCardPopupState;

            // Make the other popup menu invisible
            if (otherState.visible) {
                otherSetterFunc({ ...otherState, visible: false });
            }

            if (!currentState.visible) {
                if ((clickOnCard && !cardPopupListenerCreated) || (!clickOnCard && !emptyPopupListenerCreated)) {
                    const curListenerSetter = clickOnCard ? setCardPopupListenerCreated : setEmptyPopupListenerCreated;
                    curListenerSetter(true);

                    documentRef.current.addEventListener(`click`, function onClickOutside() {
                        setterFunc({ ...currentState, visible: false });
                        curListenerSetter(false);
                        documentRef.current.removeEventListener(`click`, onClickOutside);
                    });
                }
            }

            setterFunc({
                record: record,
                target: clickOnCard ? event["target"].parentElement.getElementsByClassName("CourseCardCode")[0].innerText : null,
                visible: true,
                x: event.clientX,
                y: event.clientY,
            });
        },
        // Prevent default action, which terminates drop
        onDragOver: (e) => e.preventDefault(),
        onDrop: () => {
            console.log("Drop on empty row.", record);
            handleCourseCardDrop(record["term_name"], null);
        },
    });

    const tutorialTitle = <span>Tutorial</span>;

    const tutorialContent = (
        <div>
            <p>Content1</p>
            <p>Content2</p>
        </div>
    );

    useEffect(() => {
        // For action redo/undo
        window.addEventListener("keydown", handleKeyDown);
        onChange?.(formattedCourseData); // Notify the form about the change

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
        // Trigger whenever the course list changes
    }, [formattedCourseData]);

    return (
        <div className="course-table" ref={rootRef}>
            <ScheduleHeader rootRef={rootRef}/>
            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            rowHoverBg: "#ebf7ff63",
                        },
                    },
                }}
            >
                {/* Make the table's header stay in place so it doesn't move with scroll(course search and tutorial must not move) */}
                <Table
                    columns={courseListColumns}
                    dataSource={formattedCourseData}
                    showHeader={false}
                    pagination={false}
                    size="small"
                    style={{
                        transition: "all 0.5s ease-out allow-discrete",
                    }}
                    onRow={handleCourseListRightClick}
                />
                <OnEmptyPopup {...emptyPopupState} setState={setEmptyPopupState} rootRef={rootRef}/>
                <OnCardPopup
                    {...cardPopupState}
                    queryCourses={queryCourses}
                    setQueryCourses={setQueryCourses}
                    setCourseList={setFormattedCourseData}
                />
            </ConfigProvider>
        </div>
    );
}

export const Schedule = ({ formattedCourseData, setFormattedCourseData, queryCourses=null, setQueryCourses=null, onChange = null }) => {
    const payload = {
        formattedCourseData, 
        setFormattedCourseData,
        queryCourses, 
        setQueryCourses,
        onChange
    }

    return (
        <ScheduleInner {...payload}/>
        // <CourseListProvider>
        // </CourseListProvider>
    )
}

// React component to display courses in a grid layout
const CourseTable = () => {
    const { formattedCourseData, setFormattedCourseData, queryCourses, setQueryCourses, courseActionHistory, courseActionStep } = useAppContext();

    const payload = {
        formattedCourseData, setFormattedCourseData, queryCourses, setQueryCourses, courseActionHistory, courseActionStep
    }

    return (
        <Schedule
            {...payload}
        />
    )
};

export default CourseTable;
