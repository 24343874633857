import { useState, useEffect, useCallback, useRef } from 'react';
import useCourseActions from './DataVisualizer/courseActions';
import { calculateCEABData, dc, defaultFilterData, findCourseLocation, formatCourseDetails, formatUserContext, processCourseDetails, sleep, termToStr, useRequestWithNavigate } from './utils';
import { useAppContext } from './App/AppContext';

/**
 * Validates a course code of the format "CSC108H1 F", ensuring it:
 *  - Contains <course_code><campus> <session_code>
 *  - campus is one of H1, H3, H5
 *  - session_code is one of S, F, Y
 */
function validate_course_code(course_code, id) {
    // Sometimes the AI uses ece344h1 f, which is fine, just need to make it uppercase
    course_code = course_code.toUpperCase();

    let [code_raw, session_code] = course_code.split(" ");

    if (!session_code) {
        return [
            false,
            {
                "type": "tool_result",
                "tool_use_id": id,
                "content": `Error. The requested course code: ${course_code} is malformed. The required format is: <course_code><campus> <session_code>. For example, CSC108H1 F. You must include a space between <campus> and <session_code>.`
            }
        ];
    }

    console.log("TEST", code_raw.toUpperCase(), code_raw.toUpperCase().match(/[HY]\d /));
    if (!code_raw.toUpperCase().match(/[HY]\d/)) {
        return [
            false,
            {
                "type": "tool_result",
                "tool_use_id": id,
                "content": `The requested course code: ${course_code} is missing the location information. Please provide the location information. Available locations are: H1 (St. George Campus), H3 (Scarborough Campus), and H5 (Mississauga Campus)`
            }
        ];
    }

    if (session_code !== 'S' && session_code !== 'F' && session_code !== 'Y') {
        return [
            false,
            {
                "type": "tool_result",
                "tool_use_id": id,
                "content": `The requested course code: ${course_code} is missing or invalid session information. Please provide one of: S (Spring), F (Fall), Y (Yearly).`
            }
        ];
    }

    return [true, null];
}

const useAssistantActions = () => {
    const {
        scheduleData,
        activeProfile,
        currentUserInfo,
        essentialCourseData,
        kernelDepthCourseData
    } = useAppContext();

    const [CSCoursesData, setCSCoursesData] = useState({});
    const [HSSCoursesData, setHSSCoursesData] = useState({});

    const internToolPrompts = useRef({});

    useEffect(() => {
        // Initialize CS and HSS data
        fetch('/CS_courses.json')
            .then((response) => response.json())
            .then((data) => {
                setCSCoursesData(data);
            })

        fetch('/HSS_courses_grouped_by_prefix_deduped.json')
            .then((response) => response.json())
            .then((data) => {
                setHSSCoursesData(data);
            })

        fetch('/internal_tools_prompts/get_all_area_courses_table.md').then((response) => {
            return response.text();
        }
        ).then((data) => {
            internToolPrompts.current['get_all_area_courses_table'] = data;
        });

        fetch('/internal_tools_prompts/get_minor_requirements.md').then((response) => {
            return response.text();
        }
        ).then((data) => {
            internToolPrompts.current['get_minor_requirements'] = data;
        });

        fetch('/internal_tools_prompts/get_program_dates.md').then((response) => {
            return response.text();
        }
        ).then((data) => {
            internToolPrompts.current['get_program_dates'] = data;
        });

        fetch('/internal_tools_prompts/get_popular_courses.md').then((response) => {
            return response.text();
        }
        ).then((data) => {
            internToolPrompts.current['get_popular_courses'] = data;
        });
    }, [])

    // Just for debugging/logging
    // useEffect(() => {
    //     console.log("assistant action formatted course data updated:", formattedCourseData);
    // }, [formattedCourseData]);

    const request = useRequestWithNavigate();
    const { addCourse, addTerm, moveCourse, deleteCourse } = useCourseActions();

    // Define the available functions for the AI assistant to use
    const availableActions = [
        {
            "name": "add_course",
            "description": "Add a new course specified by the course code to the user's current profile. Returns the action result and a short message. Yearly courses will be added to both F and S terms.",
            "input_schema": {
                "type": "object",
                "properties": {
                    "course_code": {
                        "type": "string",
                        "description": "The code of the course to add, e.g. CSC108H1 F",
                    },
                    "term": {
                        "type": "string",
                        "description": "The term in which the course is to be added, e.g. 20231 (which means 2023 Winter term)",
                    },
                },
                "required": ["course_code", "term"],
            },
        },
        {
            "name": "move_course",
            "description": "Move a course specified by the course code from one term to another. Returns the action result and a short message.",
            "input_schema": {
                "type": "object",
                "properties": {
                    "course_code": {
                        "type": "string",
                        "description": "The code of the course to be moved, e.g. ECE344H1 F.",
                    },
                    "source_term": {
                        "type": "string",
                        "description": "The term in which the course was previously located, e.g. 20231 (which means 2023 Winter term)",
                    },
                    "target_term": {
                        "type": "string",
                        "description": "The term to which the course is to be moved, e.g. 20239 (which means 2023 Fall term)",
                    },
                },
                "required": ["course_code", "source_term", "target_term"],
            },
        },
        {
            "name": "delete_course",
            "description": "Delete a course specified by the course code. Returns the action result and a short message. When deleting yearly courses, the course will be deleted in both terms.",
            "input_schema": {
                "type": "object",
                "properties": {
                    "course_code": {
                        "type": "string",
                        "description": "The code of the course that needs to be deleted, e.g. LIN101H1 F.",
                    },
                    "source_term": {
                        "type": "string",
                        "description": "The term the course is in, e.g. 20231 (which means 2023 Winter term)",
                    },
                },
                "required": ["course_code", "source_term"],
            },
        },
        {
            "name": "query_course",
            "description": "Query the details of a course specified by the course code. Returns data incldues: course code, name, description, prerequisites, corequisites, exclusions, ratings, and student reviews. You can't move a yearly course.",
            "input_schema": {
                "type": "object",
                "properties": {
                    "course_code": {
                        "type": "string",
                        "description": "The code of the course to add, e.g. CSC108H1 F. Note that the term must be provided, e.g. CSC108 is not legal. Available terms are S (Spring), F (Fall), and Y (Yearly).",
                    }
                },
                "required": ["course_code"],
            },
        },
        {
            "name": "add_term",
            "description": "Add a term to the user's current profile. Returns the action result and a short message.",
            "input_schema": {
                "type": "object",
                "properties": {
                    "termName": {
                        "type": "number",
                        "description": "The name of the term to be added. For example, 20219 would be the term for Fall 2021; 20245 would be the term for Summer 2024; 20221 would be the winter of 2022. The term name must be unique.",
                    }
                },
                "required": ["termName"],
            },
        },
        {
            "name": "get_area_courses",
            "description": "Get all courses in a specific area. Returns a list of course codes in the specified area.",
            "input_schema": {
                "type": "object",
                "properties": {
                    "area": {
                        "type": "number",
                        "description": "The area number to query. Available areas are 1-7. For example, 1 is for area 1, 2 is for area 2, and so on.",
                    }
                },
                "required": ["area"],
            },
        },
        {
            "name": "get_approved_cs_departments",
            "description": "Get all approved Complementary Studies (CS) departments. Returns a list of course codes prefixes. For example [JRE, ...].",
            "input_schema": { "type": "object", "properties": {} },
        },
        {
            "name": "get_approved_cs_courses",
            "description": "Get all approved Complementary Studies (CS) courses. Returns a list of course codes. The course code could include wildcards characters(*).",
            "input_schema": {
                "type": "object",
                "properties": {
                    "prefix": {
                        "type": "string",
                        "description": "The prefix of the course code. For example, 'MUS' is the prefix for all courses in the music department.",
                    }
                },
                "required": ["prefix"],
            },
        },
        {
            "name": "get_approved_hss_departments",
            "description": "Get all approved Humanities & Social Science (HSS) departments. Returns a list of course codes prefixes. For example [APS, MUS, ...].",
            "input_schema": { "type": "object", "properties": {} },
        },
        {
            "name": "get_approved_hss_courses",
            "description": "Get all approved Humanities & Social Science (HSS) courses given the prefix. Returns a list of course codes.",
            "input_schema": {
                "type": "object",
                "properties": {
                    "prefix": {
                        "type": "string",
                        "description": "The prefix of the course code. For example, 'MUS' is the prefix for all courses in the music department.",
                    }
                },
                "required": ["prefix"],
            },
        },
        {
            "name": "search_courses",
            "description": "Search for courses based on the provided prefix. Returns a list of courses that match the prefix.",
            "input_schema": {
                "type": "object",
                "properties": {
                    "prefix": {
                        "type": "string",
                        "description": "The prefix of the course code to search for. Sugguestion: Always include number in the prefix. Good: ECE3 (search for all ECE courses in the 300-level). Bad: LIN (returns all linguistic courses, a lot of output). Note that the wildcard character is not allowed in this input.",
                    }
                },
                "required": ["prefix"],
            },
        },
        {
            "name": "get_courses_names",
            "description": "Get the names of the courses specified by the course codes. Returns a list of course names. This tool can be used to check if a course is valid. Return a list of course names. If a course is not valid or does not exist, the name will be DNE.",
            "input_schema": {
                "type": "object",
                "properties": {
                    "codes": {
                        "type": "array",
                        "description": "The list of course codes to get the names of. For example, ['CSC108H1', 'MAT137H1']. The course codes must be in the format of: <course_code><campus>. MAT123 will not be valid as it does not have campus specifier.",
                    }
                },
                "required": ["codes"]
            }
        },
        {
            "name": "get_user_profile",
            "description": "Get the user's current profile. Returns the user's current profile, including the schedule, graduation status, CEAB status, and other information.",
            "input_schema": {
                "type": "object",
                "properties": {}
            }
        },
        {
            "name": "get_program_dates",
            "description": "Get the important dates related to the program. Returns the dates in a markdown table.",
            "input_schema": {
                "type": "object",
                "properties": {}
            }
        },
        {
            "name": "get_all_area_courses_table",
            "description": "Get all area courses in a table format. Returns a markdown table with the course codes, names, etc. for each area.",
            "input_schema": {
                "type": "object",
                "properties": {}
            }
        },
        {
            "name": "get_popular_courses",
            "description": "Get the most popular courses. Returns a list of the most popular courses in the program.",
            "input_schema": {
                "type": "object",
                "properties": {}
            }
        },
        {
            "name": "get_minor_requirements",
            "description": "Get the requirements for a both AI and Business minors. Returns the requirements and recommendations.",
            "input_schema": {
                "type": "object",
                "properties": {}
            }
        },
        {
            "name": "get_similar_courses",
            "description": "Sementically search similar courses. For example, if you want to find similar courses to CSC108H1, you can use this tool. Returns a list of similar courses with name and description. The result might include the queried course itself.",
            "input_schema": {
                "type": "object",
                "properties": {
                    "code": {
                        "type": "string",
                        "description": "The course code to search for. For example, 'ECE344H1 F'.",
                    }
                },
                "required": ["code"],
            },
            "cache_control": { "type": "ephemeral" }
        }
    ]

    const internalTools = [
        "get_minor_requirements",
        "get_popular_courses",
        "get_all_area_courses_table",
        "get_program_dates"
    ]

    /**
     * Helper to format the returned message from an action
     */
    const formatActionResult = useCallback((success, response, result) => {
        let result_string = success ? "Success. " : "Error. ";
        
        result_string += "The action was " + (success ? "successful" : "unsuccessful") + ": " + response;
        result_string += "\n\n";

        if (result) {
            result_string += "Here is the result of the action: \n\n";
            result_string += JSON.stringify(result, null, 2);
        }

        return result_string;
    }, []);

    /**
     * Wrapper to add a course to a specified term, ensuring all validations are done first.
     */
    const addCourseWrapper = async (course_code, targetTerm, id) => {
        let cur_schedule = scheduleData.current;
        let course_payload = null;
        let filter = dc(defaultFilterData);
        let action_result = false;
        let action_response = "";

        filter['semester'] = [];

        // Validate the course code
        let [code_valid, error] = validate_course_code(course_code, id);
        if (!code_valid) {
            return error;
        }

        if (Number(targetTerm) <= 20241) {
            return {
                "type": "tool_result",
                "tool_use_id": id,
                "content": formatActionResult(false, "The requested term is in the past. Please only add course to future terms.", null)
            }
        }

        // Check if the term exists
        let term_exists = false;
        for (const scheduleTerm of cur_schedule) {
            if (Number(scheduleTerm.term_name) === Number(targetTerm)) {
                term_exists = true;
                break;
            }
        }

        if (!term_exists) {
            return {
                "type": "tool_result",
                "tool_use_id": id,
                "content": `Error. The action was not successful for the following reason:\nThe requested term: ${targetTerm} does not exist. Available terms are: ${cur_schedule.map((term) => term.term_name)}`
            };
        }

        // Request the course info from the server
        let course_error = await request("search_courses", {
            prefix: course_code,
            ...dc(filter)
        }).then((course_data) => {
            if (course_data.length === 0) {
                action_result = false;
                action_response = `Requested course (${course_code}) does not exist.`;

                return {
                    "type": "tool_result",
                    "tool_use_id": id,
                    "content": formatActionResult(action_result, action_response, null)
                };
            }

            let data = course_data[0];
            let course_details = processCourseDetails(data);
            course_payload = course_details;

            console.log("Got data payload:", course_payload);
        });

        if (course_error) {
            return course_error;
        }

        // Find the index of the target term
        let target_term_row = null;
        for (let i = 0; i < cur_schedule.length; i++) {
            let term = cur_schedule[i];
            if (Number(term.term_name) === Number(targetTerm)) {
                target_term_row = i;
                break;
            }
        }

        if (target_term_row === null) {
            action_result = false;
            action_response = `Requested term ${targetTerm} does not exist.`;
            return {
                "type": "tool_result",
                "tool_use_id": id,
                "content": formatActionResult(action_result, action_response, null)
            };
        }

        console.log("Asssitant add course", course_payload, target_term_row)

        // Finally, add the course
        const [res_success, res_message] = addCourse(course_payload, target_term_row, undefined, true);

        return {
            "type": "tool_result",
            "tool_use_id": id,
            "content": formatActionResult(res_success, res_message, null)
        };
    }

    /**
     * Wrapper to add a term, ensuring the term doesn't already exist.
     */
    const addTermWrapper = async (termName, id) => {
        let term_location = 0;

        if (Number(termName) % 10 !== 1 && Number(termName) % 10 !== 9) {
            return {
                "type": "tool_result",
                "tool_use_id": id,
                "content": formatActionResult(false, "The requested term is not a valid term. Please only add fall(9) terms and spring/winter terms(1).", null)
            }
        }

        if (Number(termName) <= 20241) {
            return {
                "type": "tool_result",
                "tool_use_id": id,
                "content": formatActionResult(false, "The requested term is in the past. Please only add future terms.", null)
            }
        }

        // Check if term already exists, also figure out insertion position
        for (let i = 0; i < scheduleData.current.length; i++) {
            let term = scheduleData.current[i];
            if (term.term_name === termName) {
                return {
                    "type": "tool_result",
                    "tool_use_id": id,
                    "content": formatActionResult(false, "The requested term already exists.", null)
                };
            }

            if (term.term_name < termName) {
                term_location = i + 1;
            }
        }

        // Use the addTerm action
        addTerm(termName, term_location, [], true);

        return {
            "type": "tool_result",
            "tool_use_id": id,
            "content": formatActionResult(true, `The requested term (${termName}) was added successfully.`, null)
        };
    }

    /**
     * Wrapper to query course details, first validating the code.
     */
    const queryCourseWrapper = useCallback(
        async (course_code, id) => {
            let [code_valid, error] = validate_course_code(course_code, id);
            if (!code_valid) {
                return error;
            }

            const course_details = await request('get_course', {
                code: course_code
            }, false)
                .then((data) => {
                    console.log("Query course", data)
                    let processed_data = processCourseDetails(data);

                    let formatted = formatCourseDetails(processed_data);

                    formatted['hint'] = data['hint'] || "No additional information available.";

                    return formatted
                })
                .catch((err) => err);

            return {
                "type": "tool_result",
                "tool_use_id": id,
                "content": `${course_code}:\n ${JSON.stringify(course_details, null, 2)}`
            };
        },
        [
            request
        ]
    );

    const deleteCourseWrapper = async (course_code, source_term, id) => {
        if (course_code.toUpperCase().match(/Y\d \w/)) {
            if (course_code.toUpperCase().split(' ')[1] === 'Y') {
                course_code = course_code.toUpperCase().split(' ')[0] + (source_term % 10 === 9 ? ' F' : " S");
            }
        }

        let [st, sc] = findCourseLocation(scheduleData.current, source_term, course_code);

        if (st === -1) {
            return {
                "type": "tool_result",
                "tool_use_id": id,
                "content": `Error. The requested source term ${source_term} does not exist. Available terms are: ${scheduleData.current.map((term) => term.term_name)}`
            };
        }

        if (sc === -1) {
            return {
                "type": "tool_result",
                "tool_use_id": id,
                "content": `Error. The requested course ${course_code} does not exist in the source term ${source_term}.`
            };
        }

        const [res_success, res_message] = deleteCourse(st, sc, true);

        return {
            "type": "tool_result",
            "tool_use_id": id,
            "content": formatActionResult(res_success, res_message, null)
        };
    }

    const moveCourseWrapper = async (course_code, source_term, target_term, id) => {
        let [st, sc] = findCourseLocation(scheduleData.current, source_term, course_code);
        let tt = findCourseLocation(scheduleData.current, target_term, null);
        let tc = 0;

        if (st === -1) {
            return {
                "type": "tool_result",
                "tool_use_id": id,
                "content": `Error. The requested course ${course_code} does not exist in the source term ${source_term}.`
            };
        }

        if (tt === -1) {
            return {
                "type": "tool_result",
                "tool_use_id": id,
                "content": `Error. The requested target term ${target_term} does not exist.`
            };
        }

        console.log("Move course:", st, sc, tt, tc);
        console.log("Schedule data:", scheduleData.current);
        const [res_success, res_message] = moveCourse(st, sc, tt, tc, true);

        return {
            "type": "tool_result",
            "tool_use_id": id,
            "content": formatActionResult(res_success, res_message, null)
        };
    }

    /**
     * Determines which action the assistant is requesting and dispatches to the correct wrapper.
     */
    const performAction = async (assistant_response) => {
        // Example of assistant_response structure:
        // {
        //   "type": "tool_use",
        //   "id": "someUniqueID",
        //   "name": "add_course",
        //   "input": { "course_code": "CSC108H1 F", "term": "20231" }
        // }

        const { name, input, id } = assistant_response;

        if (internalTools.includes(name)) {
            return {
                "type": "tool_result",
                "tool_use_id": id,
                "content": internToolPrompts.current[name]
            }
        }

        if (name === 'add_course') {
            const { course_code, term } = input;
            return await addCourseWrapper(course_code, term, id);
        } else if (name === 'query_course') {
            const { course_code } = input;
            return await queryCourseWrapper(course_code, id);
        } else if (name === 'add_term') {
            const { termName } = input;
            return await addTermWrapper(termName, id);
        } else if (name === 'delete_course') {
            const { course_code, source_term } = input;
            return await deleteCourseWrapper(course_code, source_term, id);
        } else if (name === 'move_course') {
            const { course_code, source_term, target_term } = input;
            return await moveCourseWrapper(course_code, source_term, target_term, id);
        } else if (name === 'get_area_courses') {
            const { area } = input;
            let course_data = await request('search_area_courses', {
                area: String(area)
            }).then((data) => {
                console.log("Got data", data);
                return data
            })

            if (!course_data) {
                return {
                    "type": "tool_result",
                    "tool_use_id": id,
                    "content": `Error. There was an error getting the courses in area ${area}. This area is not recognized. Available areas are 1-7.`
                };
            }

            let formatted_courses_str = "";
            let covered_courses = {};

            for (const course of course_data) {
                let [raw_code, term_identifier] = course.code.split(' ');
                if (!(raw_code in covered_courses)) {
                    covered_courses[raw_code] = {
                        terms: [term_identifier],
                        name: course.name,
                        code: raw_code,
                        type: course.type
                    }
                } else {
                    covered_courses[raw_code].terms.push(term_identifier);
                }
            }

            for (const course_code in covered_courses) {
                let course = covered_courses[course_code];
                formatted_courses_str += `${course.code} (${course.name}) - type ${course.type}: offered in ${course.terms.join(", ")}, \n`;
            }

            return {
                "type": "tool_result",
                "tool_use_id": id,
                "content": `Courses in area ${area}:\n${formatted_courses_str}`
            };
        } else if (name === 'get_approved_cs_departments') {
            return {
                "type": "tool_result",
                "tool_use_id": id,
                "content": "The approved departments (course prefixes) for Complementary Studies (CS) are: " + Object.keys(CSCoursesData).join(", ")
            };
        } else if (name === 'get_approved_hss_departments') {
            return {
                "type": "tool_result",
                "tool_use_id": id,
                "content": "The approved departments (course prefixes) for Humanities & Social Science (HSS) are: " + Object.keys(HSSCoursesData).join(", ")
            };
        } else if (name === 'get_approved_cs_courses') {
            let { prefix } = input;

            prefix = prefix.trim("*");

            if (!(prefix in CSCoursesData)) {
                return {
                    "type": "tool_result",
                    "tool_use_id": id,
                    "content": `Error. The requested department (course prefix) ${prefix} is not recognized. The approved departments (course prefixes) for Complementary Studies (CS) are: ${Object.keys(CSCoursesData).join(", ")}`
                };
            }

            let course_codes = CSCoursesData[prefix]['courses'];
            let note = CSCoursesData[prefix]['note'];

            return {
                "type": "tool_result",
                "tool_use_id": id,
                "content": "The following courses are approved(* is the wildcard character): " + course_codes.join(", ") + "\n" + (note ? `Special Note: ${note}` : "")
            };
        } else if (name === 'get_approved_hss_courses') {
            const { prefix } = input;

            if (!(prefix in HSSCoursesData)) {
                return {
                    "type": "tool_result",
                    "tool_use_id": id,
                    "content": `Error. The requested department (course prefix) ${prefix} is not recognized. The approved departments (course prefixes) for Humanities & Social Science (HSS) are: ${Object.keys(HSSCoursesData).join(", ")}`
                };
            }

            let course_codes = HSSCoursesData[prefix];

            return {
                "type": "tool_result",
                "tool_use_id": id,
                "content": "The following courses are approved(* is the wildcard character): " + course_codes.join(", ")
            };
        } else if (name === 'search_courses') {
            const { prefix } = input;
            let filter = dc(defaultFilterData);
            filter['semester'] = [];

            let courses = await request("search_courses", {
                prefix: prefix,
                ...filter
            }).then((data) => {
                return data;
            }).catch((e) => {
                return [];
            });

            return {
                "type": "tool_result",
                "tool_use_id": id,
                "content": `Courses matching the prefix ${prefix}:\n${courses.map((course) => `${course.code} - ${course.name}`).join("\n")}`
            }
        } else if (name === 'get_courses_names') {
            const { codes } = input;

            let course_names = await request('ai_get_course_names', {
                codes: codes
            }).then((data) => {
                console.log("AI get course names data", data);
                return data['names'];
            })

            // format the course codes with names like code: name
            let formatted_course_names = [];
            for (let i = 0; i < codes.length; i++) {
                formatted_course_names.push(`${codes[i]}: ${course_names[i]}`);
            }

            return {
                "type": "tool_result",
                "tool_use_id": id,
                "content": `Course names for the requested course codes: ${formatted_course_names.join(", ")}`
            }
        } else if (name === 'get_user_profile') {
            let ceab_data = calculateCEABData(scheduleData);
            let ceab_string = "# CEAB Status\n\n";
            ceab_string += "The CEAB status is calculated based on the current schedule.\n\n";
            ceab_string += "## Format:\n  * item name: obtained/minimum (outstanding value (minimum - projected))\n\n## Data:\n"

            for (const row of ceab_data) {
                ceab_string += `*  ${row.key}: ${row.obtained}/${row.minimum} (${row.outstanding})\n`
            }

            let profile = dc(activeProfile);
            profile.courses = scheduleData.current;

            const user_context = formatUserContext(profile, currentUserInfo, essentialCourseData.current, kernelDepthCourseData.current);

            const new_user_context = user_context + ceab_string;

            return {
                "type": "tool_result",
                "tool_use_id": id,
                "content": new_user_context
            }
        } else if (name === 'get_similar_courses'){
            const { code } = input;

            let [code_valid, error] = validate_course_code(code, id);
            if (!code_valid) {
                return error;
            }

            const query_str = await request('get_course', {
                code: code
            }, false)
            .then((data) => {
                console.log("Query course", data)
                let processed_data = processCourseDetails(data);
                let formatted = "Similar courses to:\n" + processed_data['code'] + "\n" + processed_data['description'];

                return formatted
            })
            .catch((err) => err);

            console.log("AI search similar courses query", query_str);

            return request('ai_search_similar_courses', {
                "query": query_str,
                "num_return": 5
            }, false).then((data) => {
                console.log("AI search similar courses data", data);
                data = data['results'];

                let formatted = "";
                for (const doc of data) {
                    formatted += doc['doc']
                    formatted += "\n\n";
                }

                return {
                    "type": "tool_result",
                    "tool_use_id": id,
                    "content": `Courses similar to ${code} (Note that not all courses are eligible to be taken by the student):\n${formatted}`
                }
            }
            ).catch((err) => {
                return {
                    "type": "tool_result",
                    "tool_use_id": id,
                    "content": `Error. ${err}.`
                }
            });
        } else {
            // Fallback if an unsupported action was requested
            return {
                "type": "tool_result",
                "tool_use_id": id,
                "content": "Error. The requested action is not supported. Available actions are: " +
                    JSON.stringify(availableActions.map((action) => action.name))
            };
        }
    }

    return [availableActions, performAction];
};

export default useAssistantActions;
