import React, { useState, useRef, useEffect, useMemo, useCallback, useTransition } from "react";
import { Input, Button, Menu, Tooltip, Dropdown, Layout, theme, Space, message, Popover, Collapse, ConfigProvider } from "antd";
import {
    AudioOutlined,
    CopyOutlined,
    EditOutlined,
    PauseOutlined,
    ReloadOutlined,
    CheckOutlined,
    CloseOutlined,
    DownCircleOutlined,
    RedoOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    RightOutlined,
    LeftOutlined,
    PlusCircleOutlined,
    EllipsisOutlined,
    CheckCircleOutlined,
    ToolOutlined,
    CaretRightOutlined,
    CaretRightFilled,
    LoadingOutlined,
} from "@ant-design/icons";
import "../Stylesheets/App.css";
import { alphanumerical, sleep, server_api_addr, useRequestWithNavigate, dc, compressData, calculateCEABData, formatUserContext, getTextDifferences, unformatProfileCourses } from "../utils";
import { useAppContext } from "../App/AppContext";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import useAssistantActions from "../AssistantActions";
import rehypeRaw from "rehype-raw";
import { useNavigate } from "react-router-dom";
import { useMetrics } from "../SiteMetricContext";
import rehypeSanitize, { defaultSchema } from "rehype-sanitize";
import { use } from "react";

const customSchema = {
    ...defaultSchema,
    tagNames: [...(defaultSchema.tagNames || []), "thinking"],
};

const { Panel } = Collapse;
const { TextArea } = Input;
const pfp_width = "70px";
const pfp_height = "70px";
const { Content, Sider } = Layout;

const pillStyle = {
    display: "inline-flex",
    alignItems: "center",
    borderRadius: "16px",
    fontSize: "12px",
    padding: "2px 8px",
    marginRight: "6px",
    marginTop: "6px",
    whiteSpace: "nowrap",
};

const toolUseStyle = {
    ...pillStyle,
    backgroundColor: "#fafafa",
    border: "1px solid #e6e6e6",
    color: "#666",
    cursor: 'pointer'
};

const toolResultStyle = {
    ...pillStyle,
    backgroundColor: "#e6f7ff",
    border: "1px solid #91d5ff",
    color: "#1890ff",
    cursor: 'pointer'
};

export function getRenderContentV2(chatHist) {
    const renderContent = [];
    let i = 0;

    while (i < chatHist.length) {
        const message = chatHist[i];
        const { role, content } = message;

        if (role === "assistant") {
            // Handle string content directly.
            if (typeof content === "string") {
                renderContent.push({ type: "text", content });
                i += 1;
                continue;
            }

            // Process each item in the assistant message.
            content.forEach(item => {
                if (item.type === "thinking") {
                    renderContent.push({
                        type: "thinking",
                        content: item.thinking,
                    });
                } else if (item.type === "text") {
                    renderContent.push({
                        type: "text",
                        content: item.text,
                    });
                } else if (item.type === "tool_use") {
                    // Check if the last item is already a tools block.
                    let toolsBlock;
                    if (
                        renderContent.length > 0 &&
                        renderContent[renderContent.length - 1].type === "tools"
                    ) {
                        toolsBlock = renderContent[renderContent.length - 1];
                    } else {
                        toolsBlock = { type: "tools", content: [] };
                        renderContent.push(toolsBlock);
                    }
                    toolsBlock.content.push({
                        type: "tool_use",
                        content: item,
                    });
                }
            });

            // If the next message is a user message, check for tool results.
            if (i + 1 < chatHist.length && chatHist[i + 1].role === "user") {
                const userMsg = chatHist[i + 1];
                let toolsBlock;
                if (
                    renderContent.length > 0 &&
                    renderContent[renderContent.length - 1].type === "tools"
                ) {
                    toolsBlock = renderContent[renderContent.length - 1];
                } else {
                    toolsBlock = { type: "tools", content: [] };
                    renderContent.push(toolsBlock);
                }
                userMsg.content.forEach(item => {
                    if (item.type === "tool_result") {
                        toolsBlock.content.push({
                            type: "tool_result",
                            content: item,
                        });
                    }
                });
                i += 2;
                continue;
            }
        } else {
            // Skip user messages that are not tool results (they should already be handled).
            i += 1;
            continue;
        }
        i += 1;
    }

    return renderContent;
}


function hasToolUse(msg) {
    // Helper: checks if this assistant message has a tool_use in the content array
    return (
        msg?.role === "assistant" &&
        Array.isArray(msg.content) &&
        msg.content.some((item) => item.type === "tool_use")
    );
}

function hasToolResult(msg) {
    // Helper: checks if this user message has a tool_result in the content array
    return (
        msg?.role === "user" &&
        Array.isArray(msg.content) &&
        msg.content.some((item) => item.type === "tool_result")
    );
}

// Helper function to check if a message is a thinking message.
function isThinking(message) {
    if (message.role !== "assistant") return false;
    // Check if message.content is an array and contains an element with type "thinking"
    if (Array.isArray(message.content)) {
        return message.content.some(item => item.type === "thinking");
    }
    return false;
}

function groupChatTurns(messages) {
    const groupedTurns = [];
    let i = 0;

    while (i < messages.length) {
        const currentMsg = messages[i];

        // Check if the message is an assistant message with a tool_use or a thinking message.
        if (
            currentMsg.role === "assistant" &&
            (hasToolUse(currentMsg) || isThinking(currentMsg))
        ) {
            const turn = [];

            // Group consecutive assistant messages (with tool_use or thinking) and their following user tool_result messages.
            while (
                i < messages.length &&
                (
                    (messages[i].role === "assistant" && (hasToolUse(messages[i]) || isThinking(messages[i]))) ||
                    hasToolResult(messages[i])
                )
            ) {
                turn.push(messages[i]);
                i++;
            }

            // If the next message is an assistant "final" (normal text, without tool_use or thinking), append it.
            if (
                i < messages.length &&
                messages[i].role === "assistant" &&
                !hasToolUse(messages[i]) &&
                !isThinking(messages[i])
            ) {
                turn.push(messages[i]);
                i++;
            }

            groupedTurns.push(turn);
        } else {
            // Otherwise, push this single message as its own turn.
            groupedTurns.push([currentMsg]);
            i++;
        }
    }

    return groupedTurns;
}

const CopyToClipboardButton = React.memo(({ content }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = useCallback(() => {
        navigator.clipboard
            .writeText(content)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 2000);
            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
            });
    }, [content]);

    return (
        <Tooltip placement="bottom" arrow title={"Copy"}>
            <Button
                onClick={handleCopy}
                type="text"
                size="small"
                style={{ marginLeft: "5px" }}
                shape="circle"
                icon={isCopied ? <CheckOutlined /> : <CopyOutlined />}
            />
        </Tooltip>
    );
});

const defaultStyle = {
    cursor: "pointer",
    fontSize: "30px",
    marginRight: "10px",
    transition: "transform 0.175s ease",
};

const ChatSettingDropDownButton = React.memo(({ menuItems }) => {
    const [hovered, setHovered] = useState(false);
    const [dropDownOpen, setDropDownOpen] = useState(false);

    const handleHover = useCallback(
        async (e) => {
            setHovered(e);
            if (e) {
                await sleep(150);
            }
            setDropDownOpen(e);
        },
        [setHovered, setDropDownOpen]
    );

    return (
        <Dropdown
            menu={{ items: menuItems }}
            onOpenChange={handleHover}
            open={dropDownOpen}
            trigger="click"
            placement="top"
        >
            <DownCircleOutlined
                style={{
                    ...defaultStyle,
                    transform: hovered ? "rotate(-180deg)" : "rotate(-90deg)",
                }}
            />
        </Dropdown>
    );
});

const ChatNameRow = React.memo(({ item, switchCurConv, newChatSession }) => {
    const { activeProfile, setActiveProfile, save_item_to_server, setActiveProfileRef } = useAppContext();
    const [editName, setEditName] = useState(false);
    const [inputValue, setInputValue] = useState(item.name);
    const inputRef = useRef(null);
    const origName = useRef(item.name);

    const updateRowName = useCallback(() => {
        const tempProfile = { ...activeProfile };

        for (const conversation of tempProfile.chats) {
            if (conversation.key === item.key) {
                conversation.name = inputValue;
            }
        }

        setActiveProfile(tempProfile);
        setActiveProfileRef(tempProfile);

        // TODO: Get a new endpoint specifically responsible for this
        save_item_to_server('update_profile_chats', {
            key: tempProfile.key,
            chats: tempProfile.chats
        }, true, "", 'Chat name successfully updated!', "");
    }, [activeProfile, item.key, inputValue, setActiveProfile]);

    useEffect(() => {
        if (inputRef.current) {
            if (editName) {
                inputRef.current.focus();
            } else {
                inputRef.current.blur();
            }
        }
    }, [editName]);

    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            {!editName ? (
                <span
                    onClick={() => {
                        switchCurConv(item);
                    }}
                    style={{
                        display: "inline-block",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "clip",
                        verticalAlign: "top",
                        width: "90%",
                    }}
                >
                    {item.name}
                </span>
            ) : (
                <Input
                    ref={inputRef}
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.code === "Enter") {
                            setEditName(false);
                            origName.current = inputValue;
                            updateRowName();
                        } else if (e.code === "Escape") {
                            setEditName(false);
                            setInputValue(origName.current);
                        }
                    }}
                    onBlur={() => {
                        setEditName(false);
                        updateRowName();
                    }}
                />
            )}

            <Dropdown
                trigger={"click"}
                menu={{
                    items: [
                        {
                            key: "renameConv",
                            label: "Rename",
                            onClick: () => {
                                setEditName(true);
                            },
                        },
                        {
                            key: "deleteConv",
                            label: (
                                <span
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Delete
                                </span>
                            ),
                            onClick: () => {
                                const tempProfile = { ...activeProfile };

                                if (item.key === newChatSession.current?.key) {
                                    newChatSession.current = null;
                                }
                                const newChats = tempProfile.chats.filter(
                                    (conversation) => conversation.key !== item.key
                                );
                                tempProfile.chats = newChats;

                                if (newChats.length === 0) {
                                    message.error("Can't delete the last chat.")
                                } else {
                                    // TODO: Get a new endpoint specifically responsible for this
                                    // save_item_to_server('update_profile', {profile: tempProfile}, true, "", 'Chat successfully deleted!', "");
                                    save_item_to_server('update_profile_chats', {
                                        key: tempProfile.key,
                                        chats: tempProfile.chats
                                    }, true, "", 'Chat successfully deleted!', "");
                                    setActiveProfile(tempProfile);
                                    setActiveProfileRef(tempProfile);
                                }
                            },
                        },
                    ],
                }}
            >
                <EllipsisOutlined className="ChatNameEllipse" />
            </Dropdown>
        </div>
    );
});

const SpeakModelResponseButton = React.memo(({ content }) => {
    const [isSpeaking, setIsSpeaking] = useState(false);
    const utteranceRef = useRef(null);

    const handleModelResponseReadButton = useCallback(() => {
        if (isSpeaking) {
            speechSynthesis.cancel();
            setIsSpeaking(false);
        } else {
            utteranceRef.current = new SpeechSynthesisUtterance(content);
            utteranceRef.current.onend = () => setIsSpeaking(false);
            speechSynthesis.speak(utteranceRef.current);
            setIsSpeaking(true);
        }
    }, [content, isSpeaking]);

    return (
        <Tooltip placement="bottom" arrow title={"Read"}>
            <Button onClick={handleModelResponseReadButton} type="text" size="small" shape="circle">
                <span
                    style={{
                        position: "absolute",
                        transition: "opacity 0.2s ease-out, transform 0.5s ease-out",
                        opacity: isSpeaking ? 1 : 0,
                        transform: isSpeaking ? "scale(1)" : "scale(0.8)",
                    }}
                >
                    <PauseOutlined />
                </span>
                <span
                    style={{
                        position: "absolute",
                        transition: "opacity 0.2s ease-out, transform 0.5s ease-out",
                        opacity: isSpeaking ? 0 : 1,
                        transform: isSpeaking ? "scale(0.8)" : "scale(1)",
                    }}
                >
                    <AudioOutlined />
                </span>
            </Button>
        </Tooltip>
    );
});


function parseRagContext(text) {
    const ragRegex = /<rag_context>([\s\S]*?)<\/rag_context>/g;
    let match;
    const ragContextMatches = [];

    while ((match = ragRegex.exec(text)) !== null) {
        // match[1] will be the content inside <rag_context>...</rag_context>
        let rag_context_match = match[1].trim();

        if (rag_context_match) {
            ragContextMatches.push(rag_context_match);
        }
    }

    // Remove all <rag_context> blocks from the visible text
    const textWithoutRag = text.replace(ragRegex, "");

    return {
        textWithoutRag,
        ragContextMatches,
    };
}

const ChatRowUser = React.memo(({ index, turn, regenerateResponse }) => {
    const [displayEditButton, setDisplayEditButton] = useState(true);
    const [displayEditButtonHover, setDisplayEditButtonHover] = useState(false);

    const [userInputDisabled, setUserInputDisabled] = useState(true);

    // let {textWithoutRag, ragContextMatches} = parseRagContext(turn["content"]);

    // console.log("textWithoutRag", textWithoutRag)

    const userQuery = typeof (turn["content"]) === 'object' ? turn["content"][0]["text"] : turn["content"];

    const [currentDisplayText, setCurrentDisplayText] = useState(userQuery);
    const [originalInputText, setOriginalInputText] = useState(userQuery);

    // console.log("currentDisplayText", currentDisplayText);

    const onUserRowHoverEnter = () => {
        setDisplayEditButtonHover(true);
    };

    const onUserRowHoverEnd = () => {
        setDisplayEditButtonHover(false);
    };

    const updateCurrentText = useCallback((e) => {
        setCurrentDisplayText(e.target.value);
    }, []);

    useEffect(() => {
        setCurrentDisplayText(userQuery);
    }, [turn]);

    const updateUserText = useCallback(() => {
        setDisplayEditButton(true);
        setUserInputDisabled(true);
        setOriginalInputText(currentDisplayText);
        regenerateResponse(index, currentDisplayText);
    }, [currentDisplayText, index]);

    const handleCancelEdit = useCallback(() => {
        setDisplayEditButton(true);
        setUserInputDisabled(true);
        setCurrentDisplayText(originalInputText);
    }, [originalInputText]);

    return (
        <div
            key={index}
            className="ChatRow"
            onMouseOver={onUserRowHoverEnter}
            onMouseLeave={onUserRowHoverEnd}
            style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                marginBottom: "10px",
                marginLeft: "25px",
            }}
        >
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                }}
            >
                <Button
                    onClick={() => {
                        setUserInputDisabled(false);
                        setDisplayEditButton(false);
                    }}
                    style={{
                        width: "32px",
                        height: "32px",
                        marginRight: "15px",
                        // visibility: displayEditButtonHover && displayEditButton ? "visible" : "hidden",
                    }}
                    shape="circle"
                >
                    <EditOutlined />
                </Button>

                {userInputDisabled ? (
                    <div className="UserChatContent" style={{ maxWidth: "75%" }}>
                        <p
                            style={{
                                whiteSpace: "pre-wrap",
                                display: userInputDisabled ? "" : "none",
                                marginTop: "0px",
                                marginBottom: "0px",
                            }}
                        >
                            {currentDisplayText}
                        </p>
                    </div>
                ) : (
                    <div
                        className="UserChatContent"
                        style={{
                            width: "100%",
                        }}
                    >
                        <TextArea
                            variant="borderless"
                            value={currentDisplayText}
                            style={{
                                width: "100%",
                                fontSize: "11pt",
                                color: "black",
                            }}
                            disabled={userInputDisabled}
                            onChange={updateCurrentText}
                            autoSize
                        />

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button
                                onClick={handleCancelEdit}
                                style={{
                                    display: userInputDisabled ? "none" : "",
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={updateUserText}
                                style={{
                                    display: userInputDisabled ? "none" : "",
                                    marginLeft: "15px",
                                }}
                                type="primary"
                            >
                                Okay
                            </Button>
                        </div>
                    </div>
                )}
            </div>

            <div
                style={{
                    width: pfp_width,
                    height: pfp_height,
                    backgroundImage: `url(${process.env.PUBLIC_URL}/spongebob.svg)`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    transform: "scaleX(-1)",
                }}
            />
        </div>
    );
});

const ChatRowToolResult = React.memo(({ index, tools }) => {
    let sample_tools = [
        {
            "type": "tool_use", "content": {
                "id": "tool_1",
                "name": "search",
                "type": "tool_use",
                "input": { "query": "test1" }
            }
        },
        {
            "type": "tool_result", "content": {
                "type": "tool_result",
                "content": "Search results 1",
                "tool_use_id": "tool_1"
            }
        },
        {
            "type": "tool_use", "content": {
                "id": "tool_2",
                "name": "search",
                "type": "tool_use",
                "input": { "query": "test2" }
            }
        },
        {
            "type": "tool_result", "content": {
                "type": "tool_result",
                "content": "Search results 2",
                "tool_use_id": "tool_2"
            }
        }
    ]
    if (!tools || tools.length === 0) {
        return null;
    }

    // Create a map of tool_use_ids that have corresponding results
    const toolResultIds = new Set();
    tools.forEach(tool_wrapper => {
        const tool = tool_wrapper.content;
        if (tool.type === "tool_result" && tool.tool_use_id) {
            toolResultIds.add(tool.tool_use_id);
        }
    });

    return (
        <div style={{ margin: "4px 0 8px 0" }} className="whatsthis">
            {tools.map((tool_wrapper, tool_index) => {
                let tool = tool_wrapper.content;
                if (tool.type === "tool_use") {
                    const hasResult = toolResultIds.has(tool.id);
                    
                    return (
                        <Popover
                            content={JSON.stringify(tool.input, null, 2)}
                            title={`Tool Parameters`}
                            trigger="click"
                            overlayStyle={{
                                width: "250px",
                                whiteSpace: "pre-wrap",
                                wordWrap: "break-word",
                            }}
                            key={`ToolUse-${index}-${tool_index}`}
                        >
                            <div style={toolUseStyle}>
                                <ToolOutlined style={{ marginRight: "4px" }} />
                                {tool.name}
                                {!hasResult && (
                                    <span style={{ marginLeft: "4px" }}>
                                        <LoadingOutlined spin />
                                    </span>
                                )}
                            </div>
                        </Popover>
                    );
                } else if (tool.type === "tool_result") {
                    let tool_fail = tool.content.toLowerCase().startsWith('error') ||
                                    tool.content.toLowerCase().includes('not successful') || 
                                    tool.content.toLowerCase().includes('unsuccessful') ||
                                    tool.content.toLowerCase().includes('an error');

                    return (
                        <Popover
                            content={tool.content}
                            title={`Tool Result`}
                            key={`ToolResult-${index}-${tool_index}`}
                            trigger="click"
                            overlayStyle={{
                                width: "250px",
                                whiteSpace: "pre-wrap",
                                wordWrap: "break-word",
                                // scroll enable
                                overflow: "auto",
                                maxHeight: "200px",
                            }}
                        >
                            <div style={{
                                ...toolResultStyle,
                                backgroundColor: tool_fail ? "rgb(255 213 213)" : "#e6f7ff",
                                border: tool_fail ? "1px solid #ff4d4f" : "1px solid #91d5ff",
                                color: tool_fail ? 'red' : '#1890ff',
                            }}>
                                {
                                    tool_fail ? (
                                        <CloseOutlined style={{ marginRight: "4px" }} />
                                    ) : (
                                        <CheckCircleOutlined style={{ marginRight: "4px" }} />
                                    )
                                }
                                {tool_fail ? "Failed" : "Result"}
                            </div>
                        </Popover>
                    );
                }
            })}
        </div>
    );
});

const ChatRowAI = React.memo(({ index, turn: assistant_turn_contents, cur_gen = false, generation_over = false, regenerateResponse, rag=[], curState=-1 }) => {
    console.log("Got assistant turn contents:", assistant_turn_contents);
    let render_content = getRenderContentV2(assistant_turn_contents);
    console.log("Got render content:", render_content);

    function MarkdownRender({ value, cur_renderer_lastest, generation_over }) {
        const [dotCount, setDotCount] = React.useState(0);

        React.useEffect(() => {
            let interval;
            if (!generation_over && curState !== -1) {
                interval = setInterval(() => {
                    // Cycle between 0 and 1 to alternate the ellipsis between two states.
                    setDotCount(prev => (prev + 1) % 2);
                }, 1000);
            }
            return () => {
                if (interval) clearInterval(interval);
            };
        }, [generation_over, curState]);

        const customSchema = {
            ...defaultSchema,
            attributes: {
                ...defaultSchema.attributes,
                div: [
                    ...(defaultSchema.attributes?.div || []),
                    "style",
                ],
                span: [
                    ...(defaultSchema.attributes?.span || []),
                    "style",
                ],
            },
        };

        let generation_addon = "";

        if (curState === -1) {
            generation_addon = `<span style="display:inline-block; transform: scale(4); transform-origin:center; margin-left:4px; line-height:1; translate: 0px -2px;">•</span>`;
        } else {
            // Removed the box-shadow from the style.
            const fancyStyle = "color: #555; background: #f7f7f7; padding: 0.2em 0.5em; border-radius: 3px; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-style: italic;";
            // Alternate between two and three dots based on the dotCount.
            const animatedDots = (dotCount === 0 ? ".." : "...");
            if (curState === 0) {
                console.log("Initializing...")
                generation_addon = `<span style="${fancyStyle}">Initializing${animatedDots}</span>`;
            } else if (curState === 1) {
                generation_addon = `<span style="${fancyStyle}">Gathering relevant context${animatedDots}</span>`;
            } else if (curState === 2) {
                generation_addon = `<span style="${fancyStyle}">Thinking very hard${animatedDots}</span>`;
            } else {
                generation_addon = ``;
            }
        }

        let display_value = !generation_over && cur_renderer_lastest
            ? `${value} ${generation_addon}`
            : value;

        return (
            <Markdown
                key={index}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw, [rehypeSanitize, customSchema]]}
                components={{
                    code({ children, className, ...rest }) {
                        const match = /language-(\w+)/.exec(className || "");
                        return match ? (
                            <SyntaxHighlighter
                                {...rest}
                                PreTag="div"
                                language={match[1]}
                                children={String(children).replace(/\n$/, "")}
                            />
                        ) : (
                            <code {...rest} className={className}>
                                {children}
                            </code>
                        );
                    },
                }}
            >
                {display_value}
            </Markdown>
        );
    }

    const AIRowTray = () => {
        const handleModelResponseRegenerateButton = useCallback(() => {
            console.log("Regenerate", index);
            regenerateResponse(index);
        }, []);

        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    marginTop: "15px",
                }}
            >
                {
                    render_content.length !== 0 && (
                        <>
                            <SpeakModelResponseButton content={render_content.at(-1)["initial"]} />
                            <CopyToClipboardButton content={render_content.at(-1)["initial"]} />
                        </>
                    )
                }
                {
                    index !== 0 && (
                        <Tooltip placement="bottom" title={"Retry"}>
                            <Button
                                onClick={handleModelResponseRegenerateButton}
                                type="text"
                                size="small"
                                style={{ marginLeft: "5px" }}
                                shape="circle"
                                icon={<ReloadOutlined />}
                            />
                        </Tooltip>
                    )
                }
            </div>
        )
    }

    // console.log("Got render_content:", render_content);

    let sample_render_content = [
        { "type": "text", "content": "Initial response" },
        {
            "type": "tools", "content": [
                {
                    "type": "tool_use", "content": {
                        "id": "tool_1",
                        "name": "search",
                        "type": "tool_use",
                        "input": { "query": "test1" }
                    }
                },
                {
                    "type": "tool_result", "content": {
                        "type": "tool_result",
                        "content": "Search results 1",
                        "tool_use_id": "tool_1"
                    }
                },
                {
                    "type": "tool_use", "content": {
                        "id": "tool_2",
                        "name": "search",
                        "type": "tool_use",
                        "input": { "query": "test2" }
                    }
                },
                {
                    "type": "tool_result", "content": {
                        "type": "tool_result",
                        "content": "Search results 2",
                        "tool_use_id": "tool_2"
                    }
                }
            ]
        },
        { "type": "text", "content": "Final response based on search results" }
    ]

    return (
        <div className="AIChatRow">
            <div
                style={{
                    width: pfp_width,
                    height: pfp_height,
                    backgroundImage: `url(${process.env.PUBLIC_URL}/smartgary.png)`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "top center",
                }}
            />
            <div
                style={{
                    width: "calc(90% - 100px)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                }}
            >
                {rag.length > 0 && (
                    <Popover
                        title="Relavent Context"
                        trigger="click"
                        // You could also tweak placement, etc.
                        content={(
                            <div style={{ maxWidth: 300, maxHeight: 300, overflow: "auto" }}>
                                {rag.map((rag, i) => (
                                    <React.Fragment key={i}>
                                        <Markdown
                                            remarkPlugins={[remarkGfm]}
                                            rehypePlugins={[rehypeRaw, [rehypeSanitize, customSchema]]}
                                        >
                                            {rag}
                                        </Markdown>
                                        {i < rag.length && <hr />}
                                    </React.Fragment>
                                ))}
                            </div>
                        )}
                    >
                        <div style={{
                            ...toolUseStyle,
                        }}>
                            Retrieved {rag.length} Relavent Context
                        </div>
                    </Popover>
                )}
                
                {
                    render_content.map((item, item_index) => {
                        if (item.type === "text") {
                            return (
                                <div
                                    key={`AIChatRow-${index}-${item_index}`}
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <MarkdownRender
                                        value={item.content}
                                        cur_renderer_lastest={cur_gen && item_index === render_content.length - 1}
                                        generation_over={generation_over}
                                    />
                                </div>
                            );
                        } else if (item.type === "tools") {
                            return (
                                <ChatRowToolResult
                                    key={`AIChatRow-${index}-${item_index}`}
                                    index={index}
                                    tools={item.content}
                                />
                            )
                        } else if (item.type === "thinking") {
                            return (
                                <div
                                    key={`AIChatRow-${index}-${item_index}`}
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <Collapse
                                        key={`Thinking-${item_index}`}
                                        ghost
                                        size="small"
                                        expandIconPosition="start"
                                        expandIcon={({ isActive }) => <CaretRightFilled rotate={isActive ? 90 : 0} />}
                                        items={[
                                            {
                                                key: `Thinking-${item.content}`,
                                                label: "Thinking",
                                                children: (
                                                    <div
                                                        style={{
                                                            borderLeft: '3px solid #ccc',
                                                            paddingLeft: '1em',
                                                            opacity: 0.7,
                                                        }}
                                                    >
                                                        {item.content}
                                                    </div>
                                                ),
                                            },
                                        ]}
                                    />

                                </div>
                            );
                        }
                    })
                }
                <AIRowTray></AIRowTray>
            </div>
        </div>
    );
});

function groupConversationsByDate(conversations) {
    const today = new Date();
    const oneDay = 24 * 60 * 60 * 1000;

    const grouped = {
        today: [],
        yesterday: [],
        "last 7 days": [],
        "last 30 days": [],
        earlier: [],
    };

    conversations.forEach((conversation) => {
        const lastChatDate = new Date(conversation["timestamp"]);
        const diffInDays = Math.floor((today - lastChatDate) / oneDay);

        if (diffInDays === 0) {
            grouped.today.push(conversation);
        } else if (diffInDays === 1) {
            grouped.yesterday.push(conversation);
        } else if (diffInDays <= 7) {
            grouped["last 7 days"].push(conversation);
        } else if (diffInDays <= 30) {
            grouped["last 30 days"].push(conversation);
        } else {
            grouped.earlier.push(conversation);
        }
    });

    // Sort conversations within each group by timestamp
    for (const key in grouped) {
        grouped[key].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    }

    return Object.entries(grouped).map(([label, sessions]) => ({ label, sessions }));
}

const ChatRows = ({ curState, curChatHist, curConversation, setCurConversation, generation_over, regenerateResponse }) => {
    let groupedConversation = groupChatTurns(curChatHist);

    console.log("groupedConversation:", dc(groupedConversation), dc(curChatHist));

    return (
        groupedConversation.map((item, index) => {
            const payload = {
                index: index,
                turn: item,
                chatHistory: curConversation,
                setChatHistory: setCurConversation,
            };

            if (item[0].role === "assistant") {
                let rag = [];
                
                if (index !== 0) {
                    const user_turn = dc(groupedConversation[index - 1])[0];
                    console.log("user_turn:", user_turn);
                    if (typeof(user_turn['content']) === 'object' && user_turn.role === "user") {
                        for(const rag_sample of user_turn.content.slice(1)){
                            rag.push(rag_sample['text']);
                        }
                        // console.log("Found rag:", rag);
                    }
                }

                return (
                    <ChatRowAI
                        {...payload}
                        key={`chatRow-${index}`}
                        cur_gen={index === groupedConversation.length - 1}
                        generation_over={generation_over}
                        regenerateResponse={regenerateResponse}
                        rag={rag}
                        curState={curState}
                    />
                );
            } else {
                payload['turn'] = item[0];
                return (
                    <ChatRowUser
                        {...payload}
                        key={`chatRow-${index}`}
                        regenerateResponse={regenerateResponse}
                    />
                )
            }

        })
    )
}


const ChatInput = ({ currentChatValue, buttonDisabled, chatSettingDropdownItems, sendChat, courseDropQC }) => {
    const [inputValue, setInputValue] = useState(currentChatValue.current);

    function clear() {
        setInputValue("");
        currentChatValue.current = "";
    }

    const handleInputChange = useCallback((event) => {
        setInputValue(event.target.value);
        currentChatValue.current = event.target.value;
    }, []);

    return (
        <Space.Compact style={{ width: "100%" }}>
            <ChatSettingDropDownButton menuItems={chatSettingDropdownItems} />

            <Input
                placeholder="Ask me anything..."
                id="ChatInterfaceUserInput"
                value={inputValue}
                onChange={handleInputChange}
                onPressEnter={() => {
                    sendChat();
                    clear();
                }}
                style={{ height: 40, borderRadius: "6px 0px 0px 6px" }}
                key={"userInput"}
                onDragOver={(e) => e.preventDefault()}
                onDrop={courseDropQC}
            />
            <Button
                type="primary" onClick={() => {
                    sendChat();
                    clear();
                }}
                loading={buttonDisabled}
                style={{ height: 40 }}
            >
                Send
            </Button>
        </Space.Compact>

    )
}

const ChatInterface = () => {
    const {
        draggingCard: draggingCourse,
        queryCourses,
        showServerSaveMessage,
        setQueryCourses,
        formattedCourseData,
        activeProfile,
        setActiveProfile,
        save_item_to_server_lock,
        currentUserInfo,
        save_item_to_server,
        scheduleData,
        setActiveProfileRef,
        activeProfileRef
    } = useAppContext();

    const rootRef = useRef(null);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [currentResponseState, setCurrentResponseState] = useState(-1);

    const currentChatValue = useRef("");

    const [groupedChatHistory, setGroupedChatHistory] = useState([]);
    const [menuSelectedKey, setMenuSelectedKey] = useState("");

    const [availableActions, performAction] = useAssistantActions();

    const [curConversation, setCurConversation] = useState([]);

    const newChatSession = useRef(null);

    const request = useRequestWithNavigate();

    const { updateMetrics } = useMetrics(); // Access shared metrics via useRef

    const [chatHistoryCollapsed, setChatHistoryCollapsed] = useState(true);
    const [collapseButtonVisible, setCollapseButtonVisible] = useState(true);
    const historyBarWidth = 200;
    const refreshInterval = 100;

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    // Auto-scrolling variables and functions
    const messagesStartRef = useRef(null);
    const messagesEndRef = useRef(null);
    const messagesContainerRef = useRef(null);
    const isAutoScrolling = useRef(false);
    const userScrolled = useRef(false);

    const [isAtTop, setIsAtTop] = useState(false);
    const [isAtBottom, setIsAtBottom] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);

    const [responseError, setResponseError] = useState(null);
    const [responseOver, setResponseOver] = useState(true);
    
    const isGenerating = useRef(false);

    const navigate = useNavigate();

    const handleScroll = useCallback((e) => {
        if (responseOver) {
            if (isAutoScrolling.current) {
                isAutoScrolling.current = false;
            }
            userScrolled.current = false;
            setIsAtBottom(false);
            setIsAtTop(false);
            setIsOverflowing(false);
            return;
        }

        if (!isAutoScrolling.current) {
            userScrolled.current = true;
        }
        
        const target = e.target;
        const bottom =
            Math.round(target.scrollHeight - target.scrollTop) ===
            Math.round(target.clientHeight);
        setIsAtBottom(bottom);
    
        const top = target.scrollTop === 0;
        setIsAtTop(top);
    
        // The key change: Any scroll event during response generation 
        // that wasn't triggered by auto-scrolling should be considered a manual user scroll
        if (!isAutoScrolling.current) {
            // If we're not at the bottom, user has scrolled up
            if (!bottom) {
                userScrolled.current = true;
            } else {
                // If user scrolled to bottom manually, reset userScrolled state
                userScrolled.current = false
            }
        }
    
        const isOverflow = target.scrollHeight > target.clientHeight;
        setIsOverflowing(isOverflow);
    }, [responseOver]);

    const scrollToBottom = useCallback(() => {
        // Only auto-scroll if the user hasn't manually scrolled away
        if (userScrolled.current) return;
        
        isAutoScrolling.current = true;
    
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "instant" });
        }

        isAutoScrolling.current = false;
    }, [userScrolled]);

    useEffect(() => {
        if (isAtBottom || !userScrolled.current) {
            isAutoScrolling.current = true;
            scrollToBottom();
        }
    }, [curConversation, isAtBottom, scrollToBottom]);

    useEffect(() => {
        scrollToBottom();
    }, [scrollToBottom]);

    const switchCurConv = useCallback(
        (item) => {
            setMenuSelectedKey(item.key);
            setCurConversation(item.history);
            setChatHistoryCollapsed(true);
        },
        []
    );

    const sendChatSSERequest = useCallback(async (newConversation, updateMetrics, cur_chat_turn_worker, cur_chat_turn_use_openai) => {
        // Prepare metrics variables
        const requestBeginTime = performance.now();
        let compressionStartTime, compressionEndTime, compressionTime = 0;
        let uncompressedPayloadSize = 0;
        let trafficSize = 0;
        let payloadCompressionRatio = 1;
        let requestEndTime, requestTime;

        // The SSE endpoint
        let chat_api = "";
        if (process.env.REACT_APP_PRODUCTION === "1") {
            chat_api = `${server_api_addr}chat/`;
        } else {
            chat_api = `http://localhost:5100/chat`;
        }

        // Retrieve token (if you want to do so in a more robust way, 
        // you can pass the token in as a parameter).
        const tokens = JSON.parse(localStorage.getItem('jwt'));
        const accessToken = tokens?.access;

        // Construct headers
        let headerData = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        };

        try {
            // 1. Measure compression time
            compressionStartTime = performance.now();

            // Calculate the uncompressed payload length
            const payloadObj = {
                chat_hist: newConversation,
                worker: cur_chat_turn_worker,
                openai: cur_chat_turn_use_openai,
                utorid: currentUserInfo.utorid,
            };

            console.log("Send chat:", payloadObj);

            uncompressedPayloadSize = JSON.stringify(payloadObj).length;

            const finalData = await compressData(payloadObj, headerData);

            // Calculate traffic size (compressed payload length)
            trafficSize = typeof finalData === 'string'
                ? finalData.length
                : finalData.byteLength;

            // End compression timing
            compressionEndTime = performance.now();
            compressionTime = compressionEndTime - compressionStartTime;

            // Calculate payload compression ratio
            // (Guard against division by 0 in case something goes wrong)
            if (trafficSize !== 0) {
                payloadCompressionRatio = uncompressedPayloadSize / trafficSize;
            }

            // 2. Send request via fetch (SSE is typically just a fetch POST, 
            //    but you can also use EventSource, etc.)

            const response = await fetch(chat_api, {
                method: "POST",
                headers: headerData,
                body: finalData,
            });

            // 3. Measure total request time 
            requestEndTime = performance.now();
            requestTime = requestEndTime - requestBeginTime;

            // Construct success metrics
            let currentRequestMetrics = {
                endpoint: chat_api,
                success: true,

                // Payload metrics
                uncompressed_payload_size: uncompressedPayloadSize,
                payload_size: trafficSize,
                payload_compression_ratio: payloadCompressionRatio,
                compression_time: compressionTime,

                // Request timing
                request_time: requestTime,

                contribute_to_overall: false
            };

            // 4. Return the Response object so the caller can handle the SSE stream
            return [response, currentRequestMetrics];
        } catch (error) {
            // If there is an error, measure total request time anyway
            requestEndTime = performance.now();
            requestTime = requestEndTime - requestBeginTime;

            // Construct failure metrics
            let currentRequestMetrics = {
                endpoint: chat_api,
                success: false,

                // Payload metrics
                uncompressed_payload_size: uncompressedPayloadSize,
                payload_size: trafficSize,
                payload_compression_ratio: payloadCompressionRatio,
                compression_time: compressionTime,

                // Request timing
                request_time: requestTime,

                // Error info
                error_message: error.message || "Unknown error",
                error: error,

                contribute_to_overall: false
            };

            // Update metrics with error metrics
            updateMetrics(metrics => {
                // Accumulate error counts, times, etc.
                // Push currentRequestMetrics to a history array if desired.
                metrics.request_history.push(currentRequestMetrics);
            });

            setResponseError(error);
            console.error("Failed to get response from server", error);

            // Return the error so the caller can handle
            // or you could throw the error again if you want the caller
            // to catch it at a higher level
            return error;
        }
    }, [formattedCourseData, activeProfile]);

    useEffect(() => {
        if (activeProfile.chats) {
            const timeGroupChatHistory = groupConversationsByDate(
                activeProfile.chats
            );

            for (const group of timeGroupChatHistory) {
                let hist = group.sessions;
                if (hist.length === 0) {
                    continue;
                }
                setCurConversation(hist[0].history);
                break;
            }

            setGroupedChatHistory(timeGroupChatHistory);

            for (const timeGroup of timeGroupChatHistory) {
                let value = timeGroup["sessions"];

                if (value.length !== 0) {
                    setMenuSelectedKey(value[0].key);
                    break;
                }
            }
        }
    }, [activeProfile]);

    const regenerateResponse = (index, newUserContent = null) => {
        let chat_turns = groupChatTurns(dc(curConversation));
        // console.log('regen, grouped:', dc(chat_turns));
        chat_turns.splice(index);

        let new_chat = [];

        for (let turn of chat_turns) {
            for (let sub_turn of turn) {
                new_chat.push(sub_turn);
            }
        }

        if (newUserContent !== null) {
            new_chat.push({
                "role": "user",
                "content": newUserContent
            })
        }

        console.log('Regen chat:', new_chat);

        // Reset states
        setResponseOver(false);
        setResponseError(null);

        getChatResponse(new_chat);
        setCurrentResponseState(-1);
    }

    const getChatResponse = useCallback(
        async (newConversation) => {
            setButtonDisabled(true);
            let cur_chat_turn_worker = null;
            let cur_chat_turn_use_openai = null;
            let first_sub_turn = true;
            isGenerating.current = true;
            save_item_to_server_lock.current = true;

            // TODO: Handle the case where the request is not successful(eg. server down， API key disabled, etc.)
            const handle_send_and_update = async (newConversation, cur_chat_turn_worker, cur_chat_turn_use_openai) => {
                let assistant = { role: "assistant", content: "" };
                newConversation.push(assistant);
                setCurConversation([...newConversation]);
                setResponseOver(false);
                
                if (!first_sub_turn) {
                    setCurrentResponseState(-1);
                }

                const [response, request_metrics] = await sendChatSSERequest(newConversation.slice(0, -1), updateMetrics, cur_chat_turn_worker, cur_chat_turn_use_openai);
                
                setCurrentResponseState(-1);

                first_sub_turn = false;

                if (response.status !== 200) {
                    console.error("Failed to get response from server", response);

                    // setResponseError(error);

                    navigate('/error', {
                        state: {
                            htmlCode: response.data,
                        },
                    });
                    return [false, -1];
                }

                const reader = response.body.getReader();
                const decoder = new TextDecoder("utf-8");

                let num_stream = 0;
                let done = false;
                let use_tool = false;
                let cur_usage = {};
                let rag_context = [];
                let cur_worker = "EASY";
                let use_openai = true;

                let assistantTextResponse = null;

                while (!done) {
                    const { value, done: streamDone } = await reader.read();
                    done = streamDone;

                    num_stream += 1;
                    const chunk = decoder.decode(value, { stream: true });

                    // console.log("Got chunk:", chunk);

                    let splittedChunks = chunk.split("\n");

                    for (const line of splittedChunks) {
                        // await sleep(1)
                        if (!line.startsWith("data:")) {
                            continue
                        }
                        const jsonData = line.replace("data: ", "");
                        if (jsonData === "[DONE]") {
                            // setButtonDisabled(false);
                        } else {
                            try {
                                const parsedJson = JSON.parse(jsonData);

                                if (parsedJson.type === 'text') {
                                    if (typeof (assistant['content']) === 'object') {
                                        // console.log("Text after thinking push", parsedJson);
                                        if (!assistantTextResponse) {
                                            assistantTextResponse = {
                                                "type": "text",
                                                "text": parsedJson.content
                                            }
                                            assistant['content'].push(assistantTextResponse)
                                        } else {
                                            assistantTextResponse['text'] += parsedJson.content;

                                            // console.log("Assistant content", dc(assistant))
                                        }
                                    } else {
                                        // No thinking, directly text response
                                        assistant["content"] += parsedJson.content;
                                    }
                                } else if (parsedJson.type === 'tool') {
                                    use_tool = true;
                                    const tool_use_content = parsedJson.content;

                                    let assistant_response = assistant["content"];

                                    if (typeof (assistant_response) === 'string') {
                                        if (assistant_response === "") {
                                            assistant["content"] = [tool_use_content];
                                        } else {
                                            assistant["content"] = [
                                                {
                                                    "type": "text",
                                                    "text": assistant_response
                                                },
                                                tool_use_content
                                            ]
                                        }

                                    } else {
                                        assistant_response.push(tool_use_content);
                                    }
                                } else if (parsedJson.type === 'message_stop') {
                                    let cache_this = parsedJson.cache_this;

                                    if (cache_this) {
                                        console.log("Recieved cache this request.")
                                        let num_cache_points = 1;
                                        let max_num_caches = 4;
                                        let first_cache_point = -1;

                                        for (let turn_index = 0; turn_index < newConversation.length; turn_index++) {
                                            let turn = newConversation[turn_index];

                                            if (typeof (turn['content']) === 'object') {
                                                for (const part of turn['content']) {
                                                    if (part.cache_control && part.cache_control.type === 'ephemeral') {
                                                        num_cache_points += 1;
                                                        if (first_cache_point === -1) {
                                                            first_cache_point = turn_index;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        // Remove the very first cache point
                                        if (num_cache_points >= max_num_caches) {
                                            console.log("Max cache points reached, remove the first one.");
                                            delete newConversation[first_cache_point]['content'].at(-1)['cache_control']
                                            console.log(dc(newConversation[first_cache_point]['content']), dc(newConversation));
                                        }
                                        
                                        if (typeof(newConversation.at(-1)['content']) === 'object') {
                                            newConversation.at(-1)['content'].at(-1)['cache_control'] = { "type": "ephemeral" }
                                        } else {
                                            newConversation.at(-1)['content'] = [
                                                {
                                                    "type": "text",
                                                    "text": newConversation.at(-1)['content'],
                                                    "cache_control": { "type": "ephemeral" }
                                                }
                                            ]
                                        }

                                        console.log("Cache this call:", dc(newConversation));
                                    }

                                    setResponseOver(true);
                                    setResponseError(null);
                                    setButtonDisabled(false);

                                    cur_usage = parsedJson.usage;
                                } else if (parsedJson.type === 'message_start') {
                                    cur_worker = parsedJson.worker;
                                    use_openai = parsedJson.openai;
                                    setCurrentResponseState(-1);
                                    console.log("Message start:", parsedJson);
                                } else if (parsedJson.type === 'thinking_start') {
                                    // TODO: Finish these
                                    assistant['content'] = [
                                        {
                                            "type": "thinking",
                                            "thinking": "",
                                            "signature": "" // This arrives in the end, not sure if i need to include this
                                        },
                                    ]
                                } else if (parsedJson.type === 'thinking_stop') {
                                    // Do nothing
                                } else if (parsedJson.type === 'thinking_signature') {
                                    assistant['content'][0]['signature'] = parsedJson.content;
                                } else if (parsedJson.type === 'thinking') {
                                    assistant['content'][0]['thinking'] += parsedJson.content;
                                } else if (parsedJson.type === 'error') {
                                    setResponseOver(true);
                                    setButtonDisabled(false);
                                    setResponseError(parsedJson.message)
                                    console.error("Error:", parsedJson.content);
                                } else if (parsedJson.type === 'initialization_start'){
                                    setCurrentResponseState(0);
                                } else if (parsedJson.type === 'context_retrieval_start'){
                                    setCurrentResponseState(1);
                                } else if (parsedJson.type === 'context_retrieval_finish'){
                                    setCurrentResponseState(2);
                                    rag_context = parsedJson.content;
                                    
                                    if (rag_context.length > 0) {
                                        console.log("Rag context:", rag_context, dc(newConversation));

                                        // If the user's turn is pure string, convert into a list
                                        if (typeof(newConversation.at(-2)['content']) !== 'object') {
                                            const user_query = newConversation.at(-2)['content'];
                                            newConversation.at(-2)['content'] = [
                                                {
                                                    "type": "text",
                                                    "text": user_query,
                                                }
                                            ]
                                        }

                                        // Attach rag context to the end of the user's message
                                        for (const ragText of rag_context) {
                                            newConversation.at(-2)['content'].push({
                                                "type": "text",
                                                "text": `RAG Context:\n${ragText}`,
                                            })
                                        }
                                    }
                                }

                                setCurConversation([...newConversation]);
                            } catch (e) {
                                console.error("There is an exception:", e, line);
                            }
                        }
                    }
                }

                console.log("Request rag context", rag_context);

                request_metrics['usage'] = cur_usage;
                request_metrics['response'] = assistant['content'];
                updateMetrics((metrics) => {
                    metrics.request_history.push(request_metrics);
                });

                return [use_tool, num_stream, cur_worker, use_openai];
            }

            while (true) {
                console.log("Send chat", dc(newConversation));
                let [use_tool, num_stream, worker_name, use_openai] = await handle_send_and_update(newConversation, cur_chat_turn_worker, cur_chat_turn_use_openai);
                
                if (!cur_chat_turn_worker) {
                    cur_chat_turn_worker = worker_name;
                    cur_chat_turn_use_openai = use_openai;
                }

                if (num_stream === -1) {
                    break;
                }

                console.log("Stream done:", num_stream, dc(newConversation));
                setCurConversation(dc(newConversation));

                // Handle tool requests
                if (use_tool) {
                    let cur_user_tool_response = {
                        "role": "user",
                        "content": []
                    }

                    for (let response of newConversation.at(-1)['content']) {
                        if (response['type'] === "text") {
                            // if (response['text'] === "") {
                            //     response['text'] = "Let me use the following tool to assist you.";
                            // }
                        } else if (response['type'] === "thinking") {
                            // Do nothing
                        } else {
                            console.log("Tool response:", response)
                            const excludeKey = 'chats';

                            const neccessaryInformation = Object.entries(activeProfile).reduce((acc, [key, value]) => {
                                if (key !== excludeKey) {
                                    acc[key] = value;
                                }
                                return acc;
                            }, {});

                            const course_updated_profile = {
                                ...neccessaryInformation,
                                courses: scheduleData.current
                            };

                            let assistant_response = dc(response);

                            let action_result = await performAction(assistant_response);
                            cur_user_tool_response['content'].push(action_result);
                        }
                    }

                    newConversation.push(cur_user_tool_response);

                    setCurConversation(dc(newConversation));

                    console.log("New conver:", newConversation);
                } else {
                    break
                }
            }

            // Delete new chat key
            if (menuSelectedKey === newChatSession.current?.key) {
                newChatSession.current = null;
            }

            let newAllChat = [];

            for (let chat of activeProfile.chats) {
                if (chat.key === menuSelectedKey) {
                    newAllChat.push({
                        ...chat,
                        history: newConversation,
                        timestamp: (new Date()).toISOString()
                    });
                } else {
                    newAllChat.push(chat);
                }
            }

            console.log('newAllChat: ', newAllChat)

            showServerSaveMessage.current = false;
            setActiveProfile(
                {
                    ...activeProfile,
                    chats: newAllChat
                }
            )

            isGenerating.current = false;

            setTimeout(() => {
                if (isGenerating.current) {
                    return;
                }

                save_item_to_server_lock.current = false;
                save_item_to_server('update_profile_chats', {
                    key: activeProfile.key,
                    chats: newAllChat
                }, true, "", 'Chat successfully saved!', "");
            }, 2000);
        },
        [menuSelectedKey, formattedCourseData, activeProfile]
    );

    const sendChat = useCallback(async () => {
        if (buttonDisabled) {
            return;
        }

        let newConversation = [
            ...curConversation,
            {
                role: "user",
                content: currentChatValue.current,
            },
        ];

        if (currentChatValue.current.length === 0) {
            if (process.env.REACT_APP_PRODUCTION !== "1") {
                // Debug mode here, generate very long random string for testing
                newConversation.at(-1)['content'] = "Debug mode, generating random string for testing";
    
                let assistant = { role: "assistant", content: "" };
                newConversation.push(assistant);
                setCurConversation([...newConversation]);
    
                while (true) {
                    let randomString = Math.random().toString(36).substring(2, 15);
                    assistant['content'] += randomString + " ";
                    setCurConversation([...newConversation]);
    
                    await sleep(10);
                    if (assistant['content'].length > 5000) {
                        break;
                    }
                }
            }

            message.error("Please input to start chatting with the assistant.");
            return;
        }

        setCurConversation(newConversation);

        getChatResponse(newConversation);
        setCurrentResponseState(-1);

        // Reset states
        setResponseOver(false);
        setResponseError(null);
        setButtonDisabled(true);
    }, [buttonDisabled, currentChatValue, curConversation, getChatResponse]);

    console.log("Chat Interface Rerender");

    const chatSettingDropdownItems = useMemo(
        () => [
            {
                key: "1",
                label: (
                    <Button
                        icon={chatHistoryCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => {
                            setChatHistoryCollapsed(!chatHistoryCollapsed);
                        }}
                        style={{
                            width: '115px',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        History
                    </Button>
                ),
            },
            {
                key: "2",
                label: (
                    <Button
                        icon={<RedoOutlined />}
                        onClick={() => {
                            startNewChat();
                        }}
                        style={{
                            width: '115px',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        New Chat
                    </Button>
                ),
            }
        ],
        [chatHistoryCollapsed]
    );

    function getConv(key) {
        for (const chat of activeProfile.chats) {
            if (chat.key === key) {
                return chat;
            }
        }

        return null;
    }

    const startNewChat = useCallback(() => {
        console.log("New chat:")
        console.log(activeProfile)
        console.log(groupedChatHistory)

        if (activeProfile.chats.length >= 5) {
            message.error("You can only have up to 5 chats at a time.");
            return;
        }

        const default_conv = [
            {
                role: "assistant",
                content: `Hi ${currentUserInfo.name}, I'm Dream Gary, how can I assist you today?`,
            },
        ];

        // If new chat key is set, then that means that the new chat is not used
        if (newChatSession.current !== null) {
            switchCurConv(newChatSession.current);
            return;
        }

        setCurConversation(default_conv);
        setChatHistoryCollapsed(false);

        const getTodayDate = () => {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, "0");
            const day = String(today.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        };

        const cur_id = alphanumerical();
        const today = new Date();
        setMenuSelectedKey(cur_id);

        let newChatData = {
            key: cur_id,
            name: `Chat ${getTodayDate()}`,
            timestamp: today.toISOString(),
            history: default_conv,
        };

        newChatSession.current = newChatData;

        setActiveProfile({
            ...activeProfile,
            chats: [
                newChatData,
                ...activeProfile.chats
            ],
        });

        setActiveProfileRef({
            ...activeProfile,
            chats: [
                newChatData,
                ...activeProfile.chats
            ],
        })
    }, [activeProfile, setActiveProfile]);

    const [showQCArea, setShowQCArea] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            if (draggingCourse.current) {
                if (draggingCourse.current.source === "index") {
                    setShowQCArea(true);
                } else {
                    setShowQCArea(false);
                }
            } else {
                setShowQCArea(false);
            }
        }, refreshInterval);

        return () => clearInterval(interval);
    }, [draggingCourse]);

    const courseDropQC = useCallback(() => {
        let newQCArray = [
            ...queryCourses,
            formattedCourseData[draggingCourse.current.term_row_index].term_courses[
            draggingCourse.current.term_course_index
            ],
        ];
        let updatedQCArray = [...new Set(newQCArray)];
        setQueryCourses(updatedQCArray);

        if (updatedQCArray.length !== newQCArray.length) {
            message.error("The selected course is already included!");
        }

        draggingCourse.current = null;
    }, [draggingCourse, formattedCourseData, queryCourses, setQueryCourses]);

    return (
        <div
            style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "rgb(250, 250, 250)",
            }}
            ref={rootRef}
        >
            <Layout style={{ width: "100%", height: "100%" }}>
                <Sider
                    style={{
                        background: colorBgContainer,
                        textAlign: "left",
                        left: chatHistoryCollapsed
                            ? `-${historyBarWidth}px`
                            : "0px",
                        overflow: "auto",
                    }}
                    width={chatHistoryCollapsed ? 0 : historyBarWidth}
                >
                    <div
                        className="AddNewChatButton"
                        onClick={() => {
                            startNewChat();
                        }}
                    >
                        <PlusCircleOutlined
                            style={{
                                fontSize: "16px",
                            }}
                        />
                        <span
                            style={{
                                marginLeft: "15px",
                                fontSize: "16px",
                                fontFamily: "Gill Sans, sans-serif",
                            }}
                        >
                            Start a new Chat
                        </span>
                    </div>
                    <Menu
                        mode="inline"
                        selectedKeys={menuSelectedKey}
                        items={[
                            {
                                type: "divider",
                                key: "divider",
                            },
                            ...groupedChatHistory
                                .filter((timeGroup) => timeGroup.sessions.length > 0)
                                .map((timeGroup) => ({
                                    key: `grp_${timeGroup.label}`,
                                    label: timeGroup.label,
                                    type: "group",
                                    children: timeGroup.sessions.map((item) => ({
                                        key: item.key,
                                        label: <ChatNameRow item={item} switchCurConv={switchCurConv} newChatSession={newChatSession} />,
                                    })),
                                })),
                        ]}
                    />
                </Sider>
                <Layout style={{ height: "100%" }}>
                    <Content
                        ref={messagesContainerRef}
                        onScroll={handleScroll}
                        style={{
                            height: "100%",
                            minWidth: "350px",
                            maxWidth: "100%",
                            overflowY: "auto",
                            paddingRight: "4px",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div ref={messagesStartRef} />
                        <Button
                            style={{
                                position: "sticky",
                                top: "50%",
                                right: "100%",
                                width: "16px",
                                borderRadius: "4px",
                                opacity: collapseButtonVisible ? "100%" : "0%",
                            }}
                            onClick={() => {
                                setChatHistoryCollapsed(!chatHistoryCollapsed);
                            }}
                            onMouseEnter={() => {
                                setCollapseButtonVisible(true);
                            }}
                            icon={
                                chatHistoryCollapsed ? (
                                    <RightOutlined />
                                ) : (
                                    <LeftOutlined />
                                )
                            }
                        />

                        <ChatRows curState={currentResponseState} curChatHist={curConversation} curConversation={curConversation} setCurConversation={setCurConversation} generation_over={responseOver} regenerateResponse={regenerateResponse} />
                        {responseError && (
                            <div
                                style={{
                                    color: "red",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                }}
                            >
                                {responseError}
                            </div>
                        )}
                        {/* Scroll to bottom button */}
                        {(userScrolled.current && !responseOver && isOverflowing) && (
                            <div
                                style={{
                                    position: 'fixed',
                                    bottom: '100px',
                                    right: '20px',
                                    zIndex: 10,
                                }}
                            >
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<DownCircleOutlined />}
                                    onClick={() => {
                                        scrollToBottom();
                                        userScrolled.current = false;
                                    }}
                                    size="large"
                                    style={{
                                        opacity: 0.8,
                                        boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                                    }}
                                />
                            </div>
                        )}

                        <div ref={messagesEndRef} />
                    </Content>
                </Layout>
            </Layout>

            <div
                style={{
                    width: "95%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                {/* {showQCArea ? (
                    <div
                        className="QCDropArea"
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={courseDropQC}
                    >
                        Drag Course Here to Start Asking Questions
                    </div>
                ) : (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: "5px",
                        }}
                    >
                        {queryCourses.map((item, iterator) => (
                            <div key={`QC-${alphanumerical()}`}>
                                <CloseOutlined
                                    className="closeButton"
                                    onClick={() => {
                                        const newData = [...queryCourses];
                                        newData.splice(iterator, 1);
                                        setQueryCourses(newData);
                                    }}
                                />

                                <div
                                    style={{
                                        width: "130px",
                                        height: "60px",
                                        marginRight: "5px",
                                        color: item["status"] === 0 ? "black" : "#f90",
                                        cursor: "default",
                                    }}
                                    className="CourseCard"
                                >
                                    <span
                                        style={{
                                            fontSize: 14,
                                            fontFamily: "arial",
                                            textDecoration: "none",
                                            color: item["status"] === 0 ? "black" : "#f90",
                                            fontWeight: "bold",
                                        }}
                                        className="CourseCardFamily CourseCardCode"
                                    >
                                        {item["code"]}
                                    </span>

                                    <div
                                        style={{
                                            fontSize: "7pt",
                                            fontFamily: "arial",
                                            lineHeight: "1",
                                        }}
                                        className="CourseCardFamily CourseCardName"
                                    >
                                        {item["name"]}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )} */}

                <ChatInput
                    currentChatValue={currentChatValue}
                    courseDropQC={courseDropQC}
                    sendChat={sendChat}
                    buttonDisabled={buttonDisabled}
                    chatSettingDropdownItems={chatSettingDropdownItems}
                />
                <span style={{ color: 'gray' }}>Dream Gary can make mistake. Please check important information.</span>
            </div>
        </div>
    );
};

export default ChatInterface;
