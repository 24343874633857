import { useState, useRef, useEffect } from "react";
import { Dropdown, Input, Popconfirm, Button, message, Popover, List } from "antd";
import { SettingOutlined, CloseCircleTwoTone, MinusCircleTwoTone, CheckCircleTwoTone } from "@ant-design/icons";
import { useAppContext } from "./AppContext";
import { dc, unformatProfileCourses, useRequestWithNavigate } from "../utils";

const ProfileRowSmall = ({ profileInfo, children, ...props }) => {
    const {
        activeProfile,
        setActiveProfile,
        allProfiles,
        setAllProfiles,
        save_item_to_server,
        setActiveProfileRef,
        setScheduleData,
        setFormattedCourseData,
    } = useAppContext();

    const request = useRequestWithNavigate();
    const [profileTitle, setProfileTitle] = useState(profileInfo.name);
    const [editName, setEditName] = useState(false);
    const inputRef = useRef(null);
    const maxProfileNameLength = 30;
    const size = 16;
    const origName = useRef(profileInfo.name);

    console.log('Inside profile row small:', profileInfo)

    useEffect(() => {
        if (inputRef.current) {
            if (editName) {
                inputRef.current.focus();
            } else {
                inputRef.current.blur();
            }
        }
    }, [editName]);

    useEffect(() => {
        console.log('abc:', profileTitle, profileInfo)
    }, [profileTitle])

    const menuItems = [
        {
            key: "RenameProfile",
            style: {
                padding: 0,
                width: '72px',
                height: '32px',
                textAlign: 'left'
            },
            label: (
                <span
                    onClick={(e) => {
                        e.stopPropagation();
                        setEditName(true);
                    }}
                >
                    &nbsp;&nbsp;&nbsp;Rename&nbsp;&nbsp;&nbsp;
                </span>
            ),
            onClick: (e) => {
                e.domEvent.stopPropagation();
            }
        },
        {
            key: "DeleteProfile",
            style: {
                padding: 0,
                width: '72px',
                height: '32px',
                textAlign: 'left'
            },
            label: (
                <Popconfirm
                    title="Delete Profile"
                    description={`Are you sure to delete this profile (${profileInfo.name})? This action is not reversible!`}
                    onConfirm={(e) => {
                        if (allProfiles.length === 1) {
                            message.error("Illegal Action: Can't delete the last profile!");

                            return;
                        }
                        const newAllProfiles = [];
                        let imActive = activeProfile.key === profileInfo.key;

                        for (const profile of allProfiles) {
                            if (profile.key !== profileInfo.key) {
                                // If the profile to be deleted is the active profile, make someother profile active
                                if (imActive) {
                                    console.log("Set new active profile", profile)
                                    setActiveProfile(profile);
                                    setActiveProfileRef(profile);

                                    setFormattedCourseData(profile.courses);
                                    setScheduleData(profile.courses);

                                    let newDefault = {
                                        ...profile,
                                        is_default: 1
                                    };
                
                                    newAllProfiles.push(newDefault);
                                    let sendPayload = dc(newDefault);
                
                                    delete sendPayload['chats']
                                    delete sendPayload['courses']
                        
                                    request('update_profile_meta', {
                                        ...sendPayload
                                    });
                                    imActive = false;

                                    newAllProfiles.push(newDefault);
                                } else {
                                    newAllProfiles.push(profile);
                                }
                            } 
                        }

                        setAllProfiles(newAllProfiles);
                        e.stopPropagation();
                        // Save to server
                        save_item_to_server('delete_profile', {
                            key: profileInfo.key
                        }, true, "", "Profile successfully deleted!", "");
                    }}
                    onCancel={(e) => {
                        e.stopPropagation();
                    }}
                    okText="Yes"
                    cancelText="No"
                    placement="top"
                    overlayStyle={{
                        width: '250px'
                    }}
                >
                    <div
                        style={{
                            color: "red",
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        &nbsp;&nbsp;&nbsp;Delete&nbsp;&nbsp;&nbsp;
                    </div>
                </Popconfirm>
            ),
            onClick: (e) => {
                e.domEvent.stopPropagation();
            }
        },
    ];

    function updateProfileName() {
        setEditName(false);

        console.log("Update profile name", {
            ...profileInfo,
            courses: unformatProfileCourses(profileInfo),
            name: profileTitle
        })

        if (activeProfile.key === profileInfo.key) {
            setActiveProfile({
                ...activeProfile,
                name: profileTitle
            })

            setActiveProfileRef({
                ...activeProfile,
                name: profileTitle
            })
        } else {
            let newAllProfiles = [];
            for (const profile of allProfiles) {
                if (profile.key === profileInfo.key) {
                    newAllProfiles.push({
                        ...profile,
                        name: profileTitle
                    });
                } else {
                    newAllProfiles.push(profile);
                }
            }

            setAllProfiles(newAllProfiles);
        }

        // TODO: Get a new endpoint that doesn't need to update all content of the profile
        // save_item_to_server('update_profile', {
        //     profile: {
        //         ...profileInfo,
        //         courses: unformatProfileCourses(profileInfo),
        //         name: profileTitle
        //     }
        // })
        let sendPayload = dc(profileInfo);
        sendPayload['name'] = profileTitle;

        delete sendPayload['chats']
        delete sendPayload['courses']

        request('update_profile_meta', {
            ...sendPayload
        });

        origName.current = profileTitle;
    }

    return (
        <div
            {...props} // spreads props so dropdown works
            style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "space-between",
                width: '100%'
            }}
        >
            <div style={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <ProfileIndicator status={profileInfo.status} details={profileInfo.details} size={size} />
                <span
                    className="profileTitle"
                    style={{
                        marginLeft: "10px",
                        fontSize: `${size}px`,
                    }}
                >
                    {editName ? (
                        <Input
                            value={profileTitle}
                            onChange={(e) => {
                                setProfileTitle(e.target.value);
                            }}
                            variant="outlined"
                            style={{
                                color: "black",
                                width: '225px'
                            }}
                            showCount={editName}
                            maxLength={maxProfileNameLength}
                            ref={inputRef}
                            onBlur={(e) => {
                                e.stopPropagation();
                                updateProfileName();
                            }}
                            onKeyDown={(e) => {
                                e.stopPropagation();
                                console.log("KEYDOWN", e);
                                if (e.code === "Enter") {
                                    updateProfileName();
                                } else if (e.code === "Escape") {
                                    setEditName(false);
                                    setProfileTitle(origName.current);
                                }
                            }}
                        />
                    ) : (
                        <span style={{}}>{profileTitle}</span>
                    )}
                </span>
            </div>

            {!editName && (
                <Dropdown menu={{ items: menuItems }} placement="top" trigger={"click"}>
                    <Button
                        icon={<SettingOutlined />}
                        style={{
                            right: "10px",

                        }}
                        onClick={(e) => {
                            e.stopPropagation(); // Prevent the click event from bubbling up to the parent
                        }}
                    />
                </Dropdown>
            )}
        </div>
    );
};


const ProfileIndicator = ({ status = 0, details, size = 32 }) => {
    const statusIndicatorStyle = {
        fontSize: `${size}px`,
    };

    // Ensure status is between 0 and 100
    status = Math.max(0, Math.min(status, 100));

    // Calculate the color based on status
    const indicatorColor = `hsl(${status}, 100%, 45%)`;

    // Define SVG paths for cross, dash, and checkmark
    const paths = [
        "M 10 10 L 30 30 M 30 10 L 10 30",
        "M 10 10 L 30 30 M 30 10 L 10 30",
        "M 10 10 L 30 30 M 30 10 L 10 30",
        "M 10 10 L 30 30 M 30 10 L 10 30",

        "M 10 14 L 30 26 M 30 14 L 10 26",
        "M 10 16 L 30 24 M 30 16 L 10 24",
        "M 10 18 L 30 22 M 30 18 L 10 22",

        "M10 20 L30 20",
        "M 10 20 L 18 22 L 30 20",
        "M 10 20 L 18 22 L 30 17",
        "M 10 20 L 18 24 L 30 17",
        "M 10 20 L 18 24 L 30 14",
        "M 10 20 L 18 26 L 30 14",
        "M 10 20 L 18 26 L 30 11",
        "M 10 20 L 18 28 L 30 11",
        "M 10 20 L 18 28 L 30 8",
        "M 10 20 L 18 30 L 30 8",
    ];

    // Determine the number of steps between cross and checkmark
    const numStates = paths.length;
    const stateIndex = Math.floor((status / 100) * (numStates - 1));

    // Select the appropriate path based on the status
    const currentPath = paths[stateIndex];

    return (
        <Popover
            placement="top"
            title={status < 100 ? `Profile Overview - ${status}% Done` : "This profile passes all graduation course requirement!"}
            content={
                details?.length !== 0 && <List
                    size="small"
                    bordered
                    dataSource={details}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                />
            }
        >
            <div style={{
                border: `${size > 24 ? 3 : 2}px solid ${indicatorColor}`,
                borderRadius: '50%',
                height: `${size}px`,
                width: `${size}px`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <svg
                    width={size}
                    height={size}
                    viewBox="0 0 40 40"
                    style={statusIndicatorStyle}
                >
                    <path
                        d={currentPath}
                        stroke={indicatorColor}
                        strokeWidth="4"
                        fill="white"
                        strokeLinecap="round"
                    />
                </svg>
            </div>
        </Popover>
    );
};

const ProfileDisplay = ({ activeProfile, children, ...props }) => {
    return (
        <div
            {...props} // spreads props so dropdown works
            style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
            }}
        >
            <ProfileIndicator status={activeProfile.status} details={activeProfile.details} size={32} />
            <span className="profileTitle" style={{ marginLeft: "10px" }}>
                {children}
            </span>
        </div>
    );
};


export { ProfileIndicator, ProfileDisplay, ProfileRowSmall };