import { message } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "./App/AppContext";
import { useMetrics } from "./SiteMetricContext";
import pako from 'pako';
import brotliPromise from 'brotli-wasm';
import ceab_default_data from "./DataVisualizer/ceab_data_default.json";
import { diffLines } from "diff";

const local_server_api_addr = 'http://localhost:8000/api/';
const aws_server_api_addr = 'https://smartgellan.compeng.gg/api/';
const server_api_addr = aws_server_api_addr;

const defaultFilterData = {
    artsci: {
        approvedOnly: false,
        type: ["cs", "hss", "other"],
    },
    artsci_enabled: true,
    avoid_conflict: false,
    ceab: [],
    eng: {
        area: ["1", "2", "3", "4", "5", "6", "7", "O"],
        type: ["kernel", "depth", "other", 'free', 'required'],
    },
    eng_enabled: true,
    numReturn: 5,
    semester: []
};


const format_course_data_source = (groupedCourses, requisites = {}) => {
    var formattedDataSource = [];
    console.log("Requisites", requisites, groupedCourses);

    let cachedTerms = [];

    localStorage.getItem('terms') ? cachedTerms = JSON.parse(localStorage.getItem('terms')) : cachedTerms = [];

    for (const [term, courses] of Object.entries(groupedCourses)) {
        const curTermCourseList = [];

        for (var i = 0; i < courses.length; i++) {
            let violation = {
                prerequistes: {}
            };

            if (courses[i]["status"] !== 0) {
                if (Object.keys(requisites).length !== 0) {
                    if (requisites['prerequistes']) {
                        if (requisites['prerequistes']['violated'] && courses[i]["code"] in requisites['prerequistes']['violated']) {
                            violation['prerequistes']['violated'] = requisites['prerequistes']['violated'][courses[i]["code"]];
                        }

                        if (requisites['prerequistes']['nonprocessable'] && courses[i]["code"] in requisites['prerequistes']['nonprocessable']) {
                            violation['prerequistes']['nonprocessable'] = requisites['prerequistes']['nonprocessable'][courses[i]["code"]];
                        }
                    }
                }
            }
            curTermCourseList.push({
                name: courses[i]["name"],
                code: courses[i]["code"],
                status: courses[i]["status"],
                term: courses[i]["term"],

                area: courses[i]["area"],
                type: courses[i]["type"],

                ceab: courses[i]["ceab"],
                twin: courses[i]["twin"],

                credit: courses[i]["credit"],

                'violation': violation,
            });
        }

        formattedDataSource.push({
            key: `${term}`,
            term_name: Number(term),
            term_courses: curTermCourseList,
        });
    }

    // Add the cached terms at the appropriate location
    for (const term of cachedTerms) {
        let allTerms = formattedDataSource.map((term) => term.term_name);
        if (allTerms.includes(term)) {
            localStorage.setItem('terms', JSON.stringify(cachedTerms.filter((item) => item !== term)));
            continue;
        }

        let i = 0;
        for (; i < formattedDataSource.length; i++) {
            if (formattedDataSource[i].term_name > term) {
                console.log("Inserting term", term, "at", i);
                formattedDataSource.splice(i, 1, {
                    key: `${term}`,
                    term_name: term,
                    term_courses: [],
                });
                break;
            }
        }

        if (i === formattedDataSource.length) {
            console.log("Inserting term", term, "at", i);
            formattedDataSource.push({
                key: `${term}`,
                term_name: term,
                term_courses: [],
            });
        }
    }

    return formattedDataSource;
};

const alphanumerical = () => {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let sequence = "";
    for (let i = 0; i < 9; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length);
        sequence += chars[randomIndex];
    }
    return sequence;
};

function findCourseLocation(formattedCourseData, term, code) {
    term = Number(term);

    var term_row_index = 0;
    var valid_term_row = false;
    for (; term_row_index < formattedCourseData.length; term_row_index++) {
        if (formattedCourseData[term_row_index]["term_name"] === term) {
            valid_term_row = true;
            break;
        }
    }

    if (!valid_term_row) {
        term_row_index = -1;
        return [-1, -1];
    }

    if (code === null) {
        return term_row_index;
    }

    var term_course_index = 0;
    var valid_course_index = false;

    for (; term_course_index < formattedCourseData[term_row_index]["term_courses"].length; term_course_index++) {
        if (formattedCourseData[term_row_index]["term_courses"][term_course_index]["code"] === code) {
            valid_course_index = true;
            break;
        }
    }

    if (!valid_course_index) {
        term_course_index = -1;
    }

    return [term_row_index, term_course_index];
}

const requestCourseBasicInfo = async (code) => {
    return axios.post(server_api_addr, {
        request: "get_course_basic",
        payload: {
            code: code,
        },
    })
        .then((response) => {
            console.log(response.data);

            // setCurrentCourseDetails(response.data);
            const data = response.data;
            const formatted_data = {
                'CS': 0,
                'ED': 0,
                'ES': 0,
                'Math': 0,
                'NS': 0,
                'area': 6,
                'code': code,
                'name': "Default name for the new course.",
                'status': 1,
                'term': null,
                'type': "C",
            }

            return formatted_data;
        })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                alert(JSON.stringify(error.response.data));
            } else {
                console.error("Error", error);
            }
            console.log("BRUHHHHH")
            const formatted_data = {
                'CS': 0,
                'ED': 0,
                'ES': 0,
                'Math': 0,
                'NS': 0,
                'area': 6,
                'code': code,
                'name': "Default name for the new course.",
                'status': 1,
                'term': null,
                'type': "C",
            }

            return formatted_data;
        });
}

const processCourseDetails = (data) => {
    return {
        name: data.name,
        code: data.code.split(' ')[0], // Does not include F/S
        session: data.code.split(' ')[1],
        description: data.description, // This could be ECE244H1 and/or ECE243H1, need a way to distinguish
        summary: data.summary,
        prerequisites: data.prerequisite,
        corequisites: ["N/A"],
        exclusions: ["N/A"],

        credit: data.credit,

        // 1, 2, 3, 4, 5, 6, 7(Science and Math), O(Not ECE)
        // Server responde with '123' (which means 1 and 2 and 3)
        area: data.area,

        // Kernel, Depth, HSS, CS, Free, None
        // ('K', 'Kernel'), ('D', 'Depth'), ('H', 'HSS'), ('C', 'CS'), ('F', 'Free'), ('O', 'Other'),
        type: data.type,

        // not Deprecated
        fall: data.code.split(' ').at(-1).trim().toLowerCase() === 'f' || data.twin ? true : false,
        winter: data.code.split(' ').at(-1).trim().toLowerCase() === 's' || data.twin ? true : false,
        summer: false,
        twin: data.twin,

        // "20249;20259;"
        offered: data.offered.split(";"),
        delivery: data.delivery,
        au_dist: data.au || [0, 0, 0, 0, 0],
        ceab: data.ceab || [0, 0, 0, 0, 0],

        // Default to in process/planned
        // status: 0,
    }
}

const requestCourseDetails = async (code) => {
    console.log('Request course details:', code)
    const tokens = JSON.parse(localStorage.getItem('jwt'));
    const accessToken = tokens?.access;
    let headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : {};

    return axios.post(server_api_addr, {
        request: "get_course",
        payload: {
            code: code,
        }
    }, {
        headers
    })
        .then((response) => {
            console.log("Got course data from server:")
            console.log(response.data);

            // setCurrentCourseDetails(response.data);
            const data = response.data;

            // return formatted_data;
            return processCourseDetails(data);
        })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                alert(JSON.stringify(error.response.data));
            } else {
                console.error("Error", error);
            }
        });
};

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

function getCourseColor(status) {
    if (status === 0) {
        return "black";
    } else if (status === 1) {
        return "#f90";
    } else {
        return "red";
    }
}

function calculateDisplayCourseCardWidth(screenWidth) {
    const cardWidth = Math.ceil(screenWidth / 180) * 10;
    console.log(cardWidth);
    return 140;
}

const HTMLRenderer = ({ htmlCode }) => {
    return (
        <div dangerouslySetInnerHTML={{ __html: htmlCode }} />
    );
};

const useRequestWithNavigateJWT = () => {
    const navigate = useNavigate();

    const api = axios.create({
        baseURL: server_api_addr,
    });

    // Function to refresh the access token
    const refreshAccessToken = async () => {
        try {
            const tokens = JSON.parse(localStorage.getItem('jwt'));
            const refreshToken = tokens?.refresh;

            const response = await axios.post(`${server_api_addr}/refresh`, {
                refresh: refreshToken,
            });

            const newAccessToken = response.data.access;
            // Update the access token in localStorage
            localStorage.setItem('jwt', JSON.stringify({
                ...tokens,
                access: newAccessToken
            }));

            return newAccessToken;
        } catch (error) {
            console.error("Refresh token is invalid or expired");
            navigate('/login'); // Redirect to login if refresh fails
            return null;
        }
    };

    // Add request interceptor to include the access token in headers
    api.interceptors.request.use(
        async (config) => {
            const tokens = JSON.parse(localStorage.getItem('jwt'));
            const accessToken = tokens?.access;

            if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    // Add response interceptor to handle 401 errors and retry failed requests
    api.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config;

            // If access token expired, try to refresh it
            if (error.response?.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;

                const newAccessToken = await refreshAccessToken();

                if (newAccessToken) {
                    originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                    return api(originalRequest); // Retry the original request
                }
            }

            return Promise.reject(error);
        }
    );

    // Wrapper function to send requests
    const request = async (endpoint, payload) => {
        try {
            const response = await api.post(
                endpoint,
                {
                    request: endpoint,
                    payload: payload
                }
            );
            return response.data;
        } catch (error) {
            if (error.response) {
                console.error(error.response.data);
                navigate('/error', {
                    state: {
                        htmlCode: error.response?.data || "<h1>Server Error</h1><p>An error occurred.</p>"
                    }
                });
            } else {
                console.error("Error", error);
                navigate('/error', {
                    state: {
                        htmlCode: "<h1>Server Error</h1><p>An error occurred.</p>"
                    }
                });
            }
            return null;
        }
    };

    return request;
};


function setItemWithExpiry(key, value, ttl) {
    const now = new Date();

    const item = {
        value: value,
        expiry: now.getTime() + ttl, // ttl is in milliseconds
    };

    localStorage.setItem(key, JSON.stringify(item));
}

// Retrieve data and check for expiration
function getItemWithExpiry(key) {
    const itemStr = localStorage.getItem(key);

    // If item doesn't exist, return null
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // If expired, remove the item and return null
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
}

function termToStr(term) {
    const term_num = term % 10;

    if (term_num === 5) {
        return 'summer'
    } else if (term_num === 1) {
        return 'winter'
    } else if (term_num === 9) {
        return 'fall'
    }

    return 'Unkown'
}

function termToChar(term) {
    const term_num = term % 10;

    if (term_num === 5) {
        return 'F'
    } else if (term_num === 1) {
        return 'S'
    } else if (term_num === 9) {
        return 'F'
    }

    return 'F'
}

async function requestAuth(dataOrToken, jwt, setAndStoreJwt) {
    const url = 'https://smartgellan.compeng.gg/api/auth_user/'

    let headers = {
        'Content-Type': 'application/json',
    };

    // Check if dataOrToken is a token and set it in the headers
    if (typeof dataOrToken === 'string') {
        headers['Authorization'] = `Bearer ${dataOrToken}`;
    }

    try {
        const response = await axios.post(url, typeof dataOrToken === 'object' ? dataOrToken : {}, {
            headers,
            withCredentials: true,
        });

        // Return the response directly if no authorization error occurs
        return response;
    } catch (error) {
        // If unauthorized, attempt to refresh the token
        if (error.response && error.response.status === 401) {
            // Attempt token refresh if jwt.access is undefined
            if (!jwt || !jwt.access) {
                setAndStoreJwt(undefined);
                throw error;
            }

            try {
                const refreshToken = jwt.refresh;
                const refreshUrl = 'https://smartgellan.compeng.gg/api/auth_user/refresh/';
                const refreshResponse = await axios.post(
                    refreshUrl,
                    { refresh: refreshToken },
                    { headers: { 'Content-Type': 'application/json' } }
                );

                const newAccessToken = refreshResponse.data.access;
                if (!newAccessToken) {
                    setAndStoreJwt(undefined);
                    throw error;
                }

                // Store the new access token and retry the request
                setAndStoreJwt({ access: newAccessToken, refresh: refreshToken });
                headers['Authorization'] = `Bearer ${newAccessToken}`;

                // Retry original request with new token
                return await axios.post(url, typeof dataOrToken === 'object' ? dataOrToken : {}, {
                    headers,
                    withCredentials: true,
                });
            } catch (refreshError) {
                setAndStoreJwt(undefined);
                throw refreshError;
            }
        } else {
            // Throw any other errors that occur during the request
            throw error;
        }
    }
}

async function compressData(dataToSend, headers, compresser_config = {
    quality: 5,
    lgwin: 22,
}) {
    const jsonString = JSON.stringify(dataToSend);
    const size = jsonString.length;

    let finalData;
    let chosenAlgo;

    // Tweak this threshold to change the compression algorithm choice
    let compression_threshold = 1_000;

    // Choose compression algorithm based on size heuristics
    if (size < compression_threshold) {
        // Small payload: no compression
        chosenAlgo = 'none';
    } else {
        // Larger payload: use brotli for better compression ratio
        chosenAlgo = 'brotli';
    }

    if (process.env.REACT_APP_PRODUCTION !== "1") {
        // Disable compression during dev(CORS)
        chosenAlgo = 'none';
    }

    if (chosenAlgo === 'brotli') {
        console.log("HEY, compressing")
        const brotli = await brotliPromise; // Import is async in browsers due to wasm requirements!

        const textEncoder = new TextEncoder();

        const uncompressedData = textEncoder.encode(jsonString);
        finalData = brotli.compress(uncompressedData, compresser_config);

        headers['Content-Encoding'] = 'br';
        // headers['Content-Type'] = 'application/octet-stream';
        headers['Content-Type'] = 'application/json';
    } else {
        // No compression
        headers['Content-Type'] = 'application/json';
        finalData = jsonString;
    }

    return finalData;
}

async function decompressData(data, contentEncoding) {
    // use the algorithm from decompressResponse
    let decompressedData = null;
    let responseData = null;

    if (contentEncoding === 'gzip') {
        // Decompress gzip response
        decompressedData = pako.ungzip(new Uint8Array(data), { to: 'string' });
    } else if (contentEncoding === 'br') {
        const brotli = await brotliPromise; // Import is async in browsers due to wasm requirements!

        const textDecoder = new TextDecoder();

        decompressedData = textDecoder.decode(brotli.decompress(data));
    } else {
        // No compression or compression handled by the browser
        const decoder = new TextDecoder();
        decompressedData = decoder.decode(new Uint8Array(data));
    }

    try {
        responseData = JSON.parse(decompressedData);
    } catch (e) {
        responseData = decompressedData;
    }

    return responseData;
}

async function decompressResponse(response) {
    console.log("Got response:", response);
    let contentEncoding = response.headers['content-encoding'];

    let decompressedData = null;

    // If the response is JSON, return it directly
    if (response.headers['content-type'] === 'application/json') {
        return response.data;
    } else {
        // Usually the browser will handle decompression, but in rare case I need to handle it
        decompressedData = decompressData(response.data, contentEncoding);
    }

    return decompressedData;
}

/**
 * Custom hook that provides a request function with navigation and metrics tracking.
 *
 * @returns {Function} request - The request function to make API calls.
 *
 * @example
 * const request = useRequestWithNavigate();
 * const response = await request('/api/endpoint', { key: 'value' });
 *
 * @typedef {Object} Metrics
 * @property {number} total_traffic - Total number of requests made.
 * @property {number} total_payload_size - Total size of all payloads sent.
 * @property {number} total_uncompressed_payload_size - Total size of all uncompressed payloads sent.
 * @property {number} avg_payload_size - Average size of payloads sent.
 * @property {number} avg_uncompressed_payload_size - Average size of uncompressed payloads sent.
 * @property {number} avg_payload_compression_ratio - Average compression ratio of payloads.
 * @property {number} total_response_size - Total size of all responses received.
 * @property {number} total_uncompressed_response_size - Total size of all uncompressed responses received.
 * @property {number} avg_response_size - Average size of responses received.
 * @property {number} avg_uncompressed_response_size - Average size of uncompressed responses received.
 * @property {number} avg_response_compression_ratio - Average compression ratio of responses.
 * @property {number} avg_communication_time - Average time taken for communication.
 * @property {number} total_compression_time - Total time taken for compression.
 * @property {number} avg_compression_time - Average time taken for compression.
 * @property {number} total_decompression_time - Total time taken for decompression.
 * @property {number} avg_decompression_time - Average time taken for decompression.
 * @property {number} total_request_time - Total time taken for requests.
 * @property {number} avg_request_time - Average time taken for requests.
 * @property {Array<Object>} request_history - History of all requests made.
 */
const useRequestWithNavigate = () => {
    const navigate = useNavigate();
    const { updateMetrics } = useMetrics(); // Access shared metrics via useRef

    const request = async (endpoint, payload, redirect = true, raw = false) => {
        // Initialize variables for metrics
        let requestBeginTime = performance.now();
        let compressionStartTime, compressionEndTime, compressionTime = 0;
        let decompressionStartTime, decompressionEndTime, decompressionTime = 0;
        let dataToSend, finalData;
        let uncompressedPayloadSize = 0, trafficSize = 0, payloadCompressionRatio = 1;
        let uncompressedResponseSize = 0, responseSize = 0, responseCompressionRatio = 1;
        let responseData;
        let requestEndTime, requestTime;

        const err = new Error();
        let call_stack = err.stack;
        call_stack = call_stack.split("\n").slice(1).map((item) => item.trim());

        try {
            const tokens = JSON.parse(localStorage.getItem('jwt'));
            const accessToken = tokens?.access;
            let headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : {};

            console.log('Send request to:', endpoint);
            console.log('Payload:', payload);

            // Measure compression time
            compressionStartTime = performance.now();
            dataToSend = { request: endpoint, payload: payload };
            uncompressedPayloadSize = JSON.stringify(dataToSend).length;

            let finalData = await compressData(dataToSend, headers);

            compressionEndTime = performance.now();
            compressionTime = compressionEndTime - compressionStartTime;

            // Calculate traffic size (compressed payload size)
            trafficSize = typeof finalData === 'string' ? finalData.length : finalData.byteLength;

            // Calculate payload compression ratio
            payloadCompressionRatio = uncompressedPayloadSize / trafficSize;

            // Record communication start time
            const startTime = performance.now();

            let response = null;

            response = await axios.post(server_api_addr, finalData, {
                headers,
            });

            const endTime = performance.now();

            // Get response data size (compressed response size)

            responseSize = Number(response.headers['content-length'] || 1);

            // Handle the response data
            decompressionStartTime = performance.now();

            responseData = await decompressResponse(response);

            decompressionEndTime = performance.now();
            decompressionTime = decompressionEndTime - decompressionStartTime;

            // Calculate uncompressed response size
            if (typeof response.data === 'object') {
                uncompressedResponseSize = JSON.stringify(responseData).length;
            } else {
                uncompressedResponseSize = response.data.byteLength;
            }

            // Calculate response compression ratio
            responseCompressionRatio = responseSize / uncompressedResponseSize;

            // Total request time
            requestEndTime = performance.now();
            requestTime = requestEndTime - requestBeginTime;

            // Collect current request metrics
            let cur_request_metrics = {
                endpoint: endpoint,
                payload: payload,
                response: responseData,

                payload_size: trafficSize,
                uncompressed_payload_size: uncompressedPayloadSize,
                payload_compression_ratio: payloadCompressionRatio,

                response_size: responseSize,
                uncompressed_response_size: uncompressedResponseSize,
                response_compression_ratio: responseCompressionRatio,

                compression_time: compressionTime,
                decompression_time: decompressionTime,
                request_time: requestTime,
                success: true,

                call_stack: call_stack,
            };

            // Update metrics using updateMetrics function
            updateMetrics((metrics) => {
                const newTotalTraffic = metrics.total_traffic + 1;
                const newTotalPayloadSize = (metrics.total_payload_size || 0) + trafficSize;
                const newTotalUncompressedPayloadSize = (metrics.total_uncompressed_payload_size || 0) + uncompressedPayloadSize;
                const newTotalCompressionTime = (metrics.total_compression_time || 0) + compressionTime;
                const newTotalDecompressionTime = (metrics.total_decompression_time || 0) + decompressionTime;
                const newTotalRequestTime = (metrics.total_request_time || 0) + requestTime;
                const newTotalResponseSize = (metrics.total_response_size || 0) + responseSize;
                const newTotalUncompressedResponseSize = (metrics.total_uncompressed_response_size || 0) + uncompressedResponseSize;

                metrics.total_traffic = newTotalTraffic;

                metrics.total_payload_size = newTotalPayloadSize;
                metrics.total_uncompressed_payload_size = newTotalUncompressedPayloadSize;
                metrics.avg_payload_size = newTotalPayloadSize / newTotalTraffic;
                metrics.avg_uncompressed_payload_size = newTotalUncompressedPayloadSize / newTotalTraffic;
                metrics.avg_payload_compression_ratio = newTotalPayloadSize / newTotalUncompressedPayloadSize;

                metrics.total_response_size = newTotalResponseSize;
                metrics.total_uncompressed_response_size = newTotalUncompressedResponseSize;
                metrics.avg_response_size = newTotalResponseSize / newTotalTraffic;
                metrics.avg_uncompressed_response_size = newTotalUncompressedResponseSize / newTotalTraffic;
                metrics.avg_response_compression_ratio = newTotalResponseSize / newTotalUncompressedResponseSize;

                metrics.avg_communication_time =
                    ((metrics.avg_communication_time || 0) * (metrics.total_traffic - 1) + (endTime - startTime)) / newTotalTraffic;

                metrics.total_compression_time = newTotalCompressionTime;
                metrics.avg_compression_time = newTotalCompressionTime / newTotalTraffic;

                metrics.total_decompression_time = newTotalDecompressionTime;
                metrics.avg_decompression_time = newTotalDecompressionTime / newTotalTraffic;

                metrics.total_request_time = newTotalRequestTime;
                metrics.avg_request_time = newTotalRequestTime / newTotalTraffic;

                if (!metrics.request_history) {
                    metrics.request_history = [];
                }
                metrics.request_history.push(cur_request_metrics);
            });

            if (!raw) {
                return responseData;
            } else {
                return response;
            }
        } catch (error) {
            // Handle errors and log metrics for failed requests
            requestEndTime = performance.now();
            requestTime = requestEndTime - requestBeginTime;

            // If compression was attempted
            if (!compressionEndTime && compressionStartTime) {
                compressionEndTime = performance.now();
                compressionTime = compressionEndTime - compressionStartTime;
            }

            if (!trafficSize && finalData) {
                trafficSize = typeof finalData === 'string' ? finalData.length : finalData.byteLength;
                payloadCompressionRatio = uncompressedPayloadSize / trafficSize;
            }

            let decomrpessedResponse = "";

            if (redirect) {
                if (error.response) {
                    // decomrpessedResponse = await decompressResponse(error.response.data);
                    // console.error('There is an error with request:', endpoint);
                    // console.error('Error:', decomrpessedResponse);
                    navigate('/error', {
                        state: {
                            htmlCode: error.response.data,
                        },
                    });
                } else {
                    console.error('Error', error, typeof (error));
                    navigate('/error', {
                        state: { htmlCode: JSON.parse(JSON.stringify(error)) },
                    });
                }
            }

            // Collect current request metrics for failed request
            let cur_request_metrics = {
                endpoint: endpoint,
                payload: payload,
                response: decomrpessedResponse,

                payload_size: trafficSize || 0,
                uncompressed_payload_size: uncompressedPayloadSize || 0,
                payload_compression_ratio: payloadCompressionRatio || 1,

                response_size: 0,
                uncompressed_response_size: 0,
                response_compression_ratio: 1,

                compression_time: compressionTime || 0,
                decompression_time: 0,
                request_time: requestTime,
                success: false,
                error_message: error.message || 'Unknown error',
                error: error,
                call_stack: call_stack,
            };

            // Update metrics using updateMetrics function
            updateMetrics((metrics) => {
                const newTotalTraffic = metrics.total_traffic + 1;
                const newTotalPayloadSize = (metrics.total_payload_size || 0) + (trafficSize || 0);
                const newTotalUncompressedPayloadSize = (metrics.total_uncompressed_payload_size || 0) + (uncompressedPayloadSize || 0);
                const newTotalCompressionTime = (metrics.total_compression_time || 0) + (compressionTime || 0);
                const newTotalRequestTime = (metrics.total_request_time || 0) + requestTime;

                metrics.total_traffic = newTotalTraffic;

                metrics.total_payload_size = newTotalPayloadSize;
                metrics.total_uncompressed_payload_size = newTotalUncompressedPayloadSize;
                metrics.avg_payload_size = newTotalPayloadSize / newTotalTraffic;
                metrics.avg_uncompressed_payload_size = newTotalUncompressedPayloadSize / newTotalTraffic;
                metrics.avg_payload_compression_ratio = newTotalUncompressedPayloadSize / newTotalPayloadSize;

                metrics.avg_communication_time =
                    ((metrics.avg_communication_time || 0) * (metrics.total_traffic - 1) + requestTime) / newTotalTraffic;

                metrics.total_compression_time = newTotalCompressionTime;
                metrics.avg_compression_time = newTotalCompressionTime / newTotalTraffic;

                metrics.total_request_time = newTotalRequestTime;
                metrics.avg_request_time = metrics.total_request_time / newTotalTraffic;

                if (!metrics.request_history) {
                    metrics.request_history = [];
                }
                metrics.request_history.push(cur_request_metrics);
            });

            if (raw) {
                return error.response;
            } else {
                return error.response.data;
            }
        }
    };

    return request;
};

const groupByTerm = (courses) => {
    if (!Array.isArray(courses)) {
        return {};
    }
    return courses.reduce((acc, course) => {
        if (!acc[course.term]) {
            acc[course.term] = [];
        }
        acc[course.term].push(course);
        return acc;
    }, {});
};

const genericCourse2UserCourse = (generic_course, term) => {
    let updated_course_code = generic_course.code.split(' ')[0];
    updated_course_code += ` ${termToChar(term)}`;
    return {
        code: updated_course_code,
        name: generic_course.name,
        term: term,
        status: generic_course.status,
        area: generic_course.area || "",
        type: generic_course.type,
        ceab: generic_course.ceab || [],
        credit: generic_course.credit || 0,

        twin: generic_course.twin,
    }
}

function unformatCourses(courses) {
    let temp_courses = []

    for (const term of courses) {
        for (const course of term.term_courses) {
            temp_courses.push(genericCourse2UserCourse(course, Number(term.term_name)))
        }
    }

    return temp_courses;
}

function unformatProfileCourses(profile) {
    if (!profile.courses) {
        return [];
    }

    return unformatCourses(profile.courses);
}

function dc(obj) {
    return JSON.parse(JSON.stringify(obj));
}

function log(message) {
    if (process.env.REACT_APP_PRODUCTION === "1") {
        console.log(message);
    }
}

// Helper function to normalize a course code by stripping off any section or term suffix
function normalizeCourseCode(code) {
    return code.split(" ")[0];
}

const calculateCEABData = (scheduleData) => {
    console.log("Recalculate CEAB");

    if (scheduleData.current.length === 0) {
        return;
    }

    // We'll track courses using normalized codes.
    let accountedCourses = [];

    // Deep copy the default CEAB details object
    const dummyCEABDetails = JSON.parse(JSON.stringify(ceab_default_data));
    for (const term of scheduleData.current) {
        for (const course of term.term_courses) {
            if (!course.ceab || course.ceab.length === 0) {
                continue;
            }

            // Normalize the course code to ensure deduplication works correctly.
            const normalizedCourseCode = normalizeCourseCode(course.code);

            // Skip if the normalized code has already been processed
            if (accountedCourses.includes(normalizedCourseCode)) {
                continue;
            }

            if (course.status !== 2) {
                dummyCEABDetails[1]["projected"] += Number((0.01 * Number(course.ceab[0] || 0)).toFixed(2));
                dummyCEABDetails[2]["projected"] += Number((0.01 * Number(course.ceab[1] || 0)).toFixed(2));
                dummyCEABDetails[3]["projected"] += Number((0.01 * Number(course.ceab[1] + course.ceab[0] || 0)).toFixed(2));
                dummyCEABDetails[4]["projected"] += Number((0.01 * Number(course.ceab[3] || 0)).toFixed(2));
                dummyCEABDetails[5]["projected"] += Number((0.01 * Number(course.ceab[4] || 0)).toFixed(2));
                dummyCEABDetails[6]["projected"] += Number((0.01 * Number(course.ceab[3] + course.ceab[3] || 0)).toFixed(2));
                dummyCEABDetails[7]["projected"] += Number((0.01 * Number(course.ceab[2] || 0)).toFixed(2));
                dummyCEABDetails[0]["projected"] += Number(
                    (0.01 * Number(
                        course.ceab[0] + course.ceab[1] + course.ceab[2] + course.ceab[3] + course.ceab[4] || 0)
                    ).toFixed(2)
                );
            }

            if (course.status === 0) {
                dummyCEABDetails[1]["obtained"] += Number((0.01 * Number(course.ceab[0] || 0)).toFixed(2));
                dummyCEABDetails[2]["obtained"] += Number((0.01 * Number(course.ceab[1] || 0)).toFixed(2));
                dummyCEABDetails[3]["obtained"] += Number((0.01 * Number(course.ceab[1] + course.ceab[0] || 0)).toFixed(2));
                dummyCEABDetails[4]["obtained"] += Number((0.01 * Number(course.ceab[3] || 0)).toFixed(2));
                dummyCEABDetails[5]["obtained"] += Number((0.01 * Number(course.ceab[4] || 0)).toFixed(2));
                dummyCEABDetails[6]["obtained"] += Number((0.01 * Number(course.ceab[3] + course.ceab[3] || 0)).toFixed(2));
                dummyCEABDetails[7]["obtained"] += Number((0.01 * Number(course.ceab[2] || 0)).toFixed(2));
                dummyCEABDetails[0]["obtained"] += Number(
                    (0.01 * Number(
                        course.ceab[0] + course.ceab[1] + course.ceab[2] + course.ceab[3] + course.ceab[4] || 0)
                    ).toFixed(2)
                );
            }

            // Add the normalized code to the list so that we don't process it again.
            accountedCourses.push(normalizedCourseCode);
        }
    }

    // Update outstanding amounts and round values appropriately.
    for (var row of dummyCEABDetails) {
        var outstanding = row["minimum"] - row["projected"];

        if (outstanding > 0) {
            row["outstanding"] = Number(outstanding.toFixed(2));
        } else {
            row["outstanding"] = "OK";
        }

        row["obtained"] = Number(row["obtained"].toFixed(2));
        row["projected"] = Number(row["projected"].toFixed(2));
    }

    console.log('dummyCEABDetails', dummyCEABDetails);
    return dummyCEABDetails;
};


function formatCourseDetails(processed_data) {
    delete processed_data['description'];
    delete processed_data['prerequisites'];
    delete processed_data['corequisites'];
    delete processed_data['exclusions'];
    delete processed_data['offered'];
    delete processed_data['au_dist'];

    let method_arr = ['lecture', 'tutorial', 'practical'];
    let ceab_arr = ['math', 'natrual science', 'complementary studies', 'engineering science', 'engineering design'];

    processed_data['delivery'] = processed_data['delivery'].map((item, index) => {
        return `${method_arr[index]}: ${item / 100}`;
    }).join(', ') + " Hours per Semester";

    processed_data['ceab'] = processed_data['ceab'].map((item, index) => {
        return `${ceab_arr[index]}: ${item / 100}`;
    }).join(', ');

    processed_data['credit'] /= 100

    let offered_terms = "";
    if (processed_data['twin']) {
        offered_terms = "Fall/Winter(Spring)";
    } else {
        if (processed_data['fall']) {
            offered_terms += "Fall";
        }

        if (processed_data['winter']) {
            if (offered_terms.length > 0) {
                offered_terms += "/Winter";
            } else {
                offered_terms += "Winter";
            }
        }
    }

    if (processed_data['summer']) {
        if (offered_terms.length > 0) {
            offered_terms += "/Summer";
        } else {
            offered_terms += "Summer";
        }
    }

    delete processed_data['fall'];
    delete processed_data['winter'];
    delete processed_data['summer'];
    delete processed_data['twin'];
    delete processed_data['session'];

    processed_data['offered_terms'] = offered_terms;

    const type_translation_table = {
        'R': 'Required',
        'K': 'Kernel',
        'D': 'Depth',
        'F': 'Free',
        'O': 'Other',
    }

    processed_data['type'] = type_translation_table[processed_data['type']];

    return processed_data;
}

// Helper to format course violation information
function formatCourseViolation(violation) {
    if (!violation) return "";
    const outputStr = [];
    const prerequistes = violation.prerequistes; // note: the key name is preserved

    if (prerequistes) {
        if (prerequistes.violated && prerequistes.violated.length > 0) {
            outputStr.push("  - The following prerequisites violations were detected by algorithm:");
            prerequistes.violated.forEach((violatedRequisite) => {
                outputStr.push(`     * ${violatedRequisite.description}`);
            });
        }

        if (prerequistes.nonprocessable && prerequistes.nonprocessable.length > 0) {
            outputStr.push("  - The following non-processable violations need to be verified by assistant:");
            prerequistes.nonprocessable.forEach((nonProcessableRequisite) => {
                outputStr.push(`     * ${nonProcessableRequisite.description}`);
            });
        }
    }
    outputStr.push("");
    return outputStr.join("\n");
}

// Main function to format the user context into Markdown
function formatUserContext(profile, userInfo, essentialCourseData, kernelDepthCourseData) {
    // Log the user info (like Python's print)
    console.log("Get user profile:", profile, userInfo, essentialCourseData, kernelDepthCourseData);

    // Format current UTC time as "YYYY-MM-DD HH:MM:SS UTC"
    const currentDate = new Date();
    const pad = (n) => (n < 10 ? "0" + n : n);
    const currentTimeStr = `${currentDate.getUTCFullYear()}-${pad(
        currentDate.getUTCMonth() + 1
    )}-${pad(currentDate.getUTCDate())} ${pad(currentDate.getUTCHours())}:${pad(
        currentDate.getUTCMinutes()
    )}:${pad(currentDate.getUTCSeconds())} UTC`;

    // The status array as in Python
    const statusArr = ["Passed", "In Progress/Planned", "Failed"];

    const mdOutput = [];

    // System-Level Information
    mdOutput.push("# System-Level Information");
    mdOutput.push(`*  **Time of Generation (UTC)**: ${currentTimeStr}`);
    mdOutput.push("");

    // User Information (using defaults if not present)
    mdOutput.push("# User Information");
    mdOutput.push(`*  **Name**: ${userInfo.name || "N/A"}`);
    mdOutput.push(`*  **Email**: ${userInfo.email || "N/A"}`);
    mdOutput.push(`*  **UTORid**: ${userInfo.utorid || "N/A"}`);
    mdOutput.push(`*  **Campus**: ${userInfo.campus || "St. George"}`);
    mdOutput.push(`*  **Program**: ${userInfo.program || "ECE"}`);
    mdOutput.push(
        `*  **Degree Post**: ${userInfo.degree ||
        "AECPEBASC (UNDERGRADUATE PROGRAM IN COMPUTER ENGINEERING)"}`
    );

    // Schedule header and data format information
    mdOutput.push(`# User Profile: ${profile.name || "N/A"}\n`);
    mdOutput.push("## Current Schedule: \n");
    mdOutput.push("### Data format: \n");
    mdOutput.push(
        "- **Course Code**: Course Name, Course Status, Course Credit, Course Area(1-7, O indicates course not part of any ECE area), Course Type(R (required), K (kernel), D (depth), F (free), O (other))\n"
    );
    mdOutput.push("---\n");

    // Iterate over each term in the profile's courses array
    const courses = profile.courses || [];
    courses.forEach((term) => {
        const termName = term.term_name || "";
        const termKey = term.key || "";
        const termCourses = term.term_courses || [];

        const numCourses = termCourses.length;
        const numCoursesCanAdd = 5 - numCourses;
        const addCourseStr = numCoursesCanAdd > 0 ? `You can add ${numCoursesCanAdd} more courses to this term.` : "Do not add more courses to this term unless you must.";
        // Term heading
        mdOutput.push(`### Term: ${termName} (Key: ${termKey}), ${numCourses} courses\n`);
        mdOutput.push(`**${addCourseStr}**\n`)

        // Iterate over courses in the term
        termCourses.forEach((course) => {
            const code = course.code || "";
            const name = course.name || "";
            const courseStatus = course.status;
            let courseCredit = course.credit || "";
            const courseArea = course.area || "N/A";
            const courseType = course.type || "";
            const violation = course.violation || {};

            // Get the formatted violation string
            const courseViolation = formatCourseViolation(violation);

            // If credit is provided, assume it is in integer form (as a string) and divide by 100.
            if (courseCredit) {
                courseCredit = parseInt(courseCredit, 10) / 100.0;
            }
            // Ensure courseCredit is a string (for concatenation)
            courseCredit = courseCredit.toString();

            // Convert the course area into a string
            let courseAreaStr;
            if (Array.isArray(courseArea)) {
                courseAreaStr = courseArea.join(" and ");
            } else if (typeof courseArea === "string") {
                courseAreaStr = courseArea.split("").join(" and ");
            } else {
                courseAreaStr = courseArea;
            }

            // Use courseStatus as an index into statusArr if it is a number.
            const statusText =
                typeof courseStatus === "number" && statusArr[courseStatus] !== undefined
                    ? statusArr[courseStatus]
                    : "";

            mdOutput.push(
                `- **${code}**: ${name}, ${courseStatus} (${statusText}), ${courseCredit}, ${courseAreaStr}, ${courseType}`
            );

            if (courseViolation) {
                mdOutput.push(courseViolation);
            }
        });

        // Blank line after each term
        mdOutput.push("");
    });

    mdOutput.push("---\n");

    // Integrate Essential Course Data
    mdOutput.push("# Essential Course Data");
    for (const category in essentialCourseData) {
        const coursesList = essentialCourseData[category];
        const courseListStr = coursesList && coursesList.length > 0 ? coursesList.join(", ") : "None";
        mdOutput.push(`- **${category}**: ${courseListStr}`);
    }
    mdOutput.push("");

    // Integrate Kernel and Depth Course Data
    mdOutput.push("# Kernel and Depth Course Data");
    mdOutput.push("This result shows the comprehensive list of kernel and depth courses in the user's current profile. You only need 8 kernel/depth courses to graduate. Refer to the **Degree Designation Table**.")
    kernelDepthCourseData.forEach((item) => {
        const area = item.area;
        const kernelCourses = item.kernel && item.kernel.length > 0 ? item.kernel.join(", ") : "None";
        const depthCourses = item.depth && item.depth.length > 0 ? item.depth.join(", ") : "None";
        mdOutput.push(`- **Area ${area}** (Key: ${item.key}):`);
        mdOutput.push(`  - Kernel: ${kernelCourses}`);
        mdOutput.push(`  - Depth: ${depthCourses}`);
    });
    mdOutput.push("");

    // Add profile status if available
    if (profile.status !== undefined && profile.status !== null) {
        mdOutput.push(`## Current Profile Completion Status: ${profile.status}%\n`);
    }

    // Add details if available
    const details = profile.details || [];
    if (details.length > 0) {
        mdOutput.push("## Outstanding Requriements:");
        details.forEach((detail) => {
            mdOutput.push(`- ${detail}`);
        });
        mdOutput.push(""); // Blank line at the end
    }

    // Return the complete Markdown string
    return mdOutput.join("\n");
}


/**
 * Generates a minimal GitHub-style diff between two texts.
 * Only changed lines are output unless a context value is provided,
 * in which case that many unchanged lines before and after each change are included.
 *
 * @param {string} oldText - The original text.
 * @param {string} newText - The updated text.
 * @param {number} [context=0] - Number of unchanged lines to include before and after changed lines.
 * @returns {string} - The diff string.
 */
function getTextDifferences(oldText, newText, context = 0) {
    const diffParts = diffLines(oldText, newText);
    const lines = [];

    // Build an array of lines with their diff type.
    diffParts.forEach(part => {
        const type = part.added ? 'added' : part.removed ? 'removed' : 'unchanged';
        const partLines = part.value.split('\n');

        partLines.forEach((line, index) => {
            // Skip a trailing empty line.
            if (index === partLines.length - 1 && line === '') return;
            lines.push({ type, text: line });
        });
    });

    // If no context is needed, filter out unchanged lines.
    if (context === 0) {
        return lines
            .filter(line => line.type !== 'unchanged')
            .map(line => (line.type === 'added' ? `+ ${line.text}` : `- ${line.text}`))
            .join('\n');
    }

    // If context is desired, mark the lines to include.
    const include = Array(lines.length).fill(false);

    // Mark changed lines and their context.
    for (let i = 0; i < lines.length; i++) {
        if (lines[i].type !== 'unchanged') {
            const start = Math.max(0, i - context);
            const end = Math.min(lines.length - 1, i + context);
            for (let j = start; j <= end; j++) {
                include[j] = true;
            }
        }
    }

    // Build the diff string including context.
    return lines
        .filter((line, index) => include[index] && line.text !== '</user_context>')
        .map(line => {
            if (line.type === 'added') return `+ ${line.text}`;
            if (line.type === 'removed') return `- ${line.text}`;
            return `  ${line.text}`;
        })
        .join('\n');
}

export { server_api_addr, defaultFilterData, getTextDifferences, formatUserContext, formatCourseDetails, calculateCEABData, unformatCourses, compressData, decompressData, decompressResponse, dc, unformatProfileCourses, genericCourse2UserCourse, groupByTerm, requestAuth, termToChar, useRequestWithNavigateJWT, processCourseDetails, termToStr, HTMLRenderer, setItemWithExpiry, getItemWithExpiry, useRequestWithNavigate, calculateDisplayCourseCardWidth, getCourseColor, format_course_data_source, alphanumerical, findCourseLocation, requestCourseDetails, sleep };
