import { useEffect, useState } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import axios from "axios";
import { sendAuthRequest } from "../utils";

const HandleLaforgeLogin = ({ setAuthenticated, setTargetUser }) => {
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const code = searchParams.get('code');
        const state = searchParams.get('state');
        const storedState = sessionStorage.getItem('state'); // CSRF protection)

        if (!code || !state) {
            setError("Missing authentication parameters.");
            return;
        }

        if (state !== storedState) {
            setError("State mismatch. Potential CSRF detected.");
            return;
        }

        const authenticate = async () => {
            try {
                // Send the `code` to the backend to exchange it for a JWT,
                const response = await axios.post('https://smartgellan.compeng.gg/api/auth_user/', {
                    code
                }, {
                    withCredentials: true, // Ensures cookies or session are included in future requests
                });

                // const response = await sendAuthRequest({'code': code});

                console.log('Got from server', response.data)

                localStorage.setItem('jwt', {
                    'access': response.data.access,
                    'refresh': response.data.refresh
                }); 

                if (response.data.utorid) {
                    console.log("Auth successfull, login with utorid:", response.data.utorid);
                    // Assuming the JWT is returned in response.data.jwt
                    localStorage.setItem('jwt', JSON.stringify({
                        'access': response.data.access,
                        'refresh': response.data.refresh
                    })); // Save the JWT
                    setTargetUser(response.data.utorid);
                    setAuthenticated(true);
                    navigate(sessionStorage.getItem('next')); // Redirect to next page
                } else {
                    setError("Authentication failed.");
                }
            } catch (err) {
                setError("An unexpected error occurred during authentication.");
                let pageCode = err.response?.data;
                if(typeof(pageCode) === 'object'){
                    pageCode = JSON.stringify(pageCode)
                }

                navigate('/error', { state: { htmlCode: pageCode || "<h1>Server Error</h1><p>An error occurred.</p>" } });
                // console.error(err);
                // console.log(err.response?.data);
            }
        };

        authenticate();

        // Clean up session storage
        return () => {
            sessionStorage.removeItem('state');
        };
    }, [searchParams, setAuthenticated, navigate]);

    if (error) {
        return (
            <div className="error-container">
                <h1>Authentication Error</h1>
                <p>{error}</p>
                <Link to="/" className="home-link">Return to Login</Link>
            </div>
        );
    }

    return (
        <div className="auth-container">
            <h2 className="auth-title">Authentication in Progress</h2>

            <img 
                className="auth-image"
                src={`${process.env.PUBLIC_URL}/robot_working.png`} 
                alt="Robot working"
            />

            <div className="auth-message">
                <p>You're being redirected to your dashboard.</p>
                <p>If this is your first time logging in, please wait while we set up your account.</p>
            </div>
        </div>
    );
};

export default HandleLaforgeLogin;

const styles = document.createElement('style');
styles.textContent = `
    .auth-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100vh;
        background-color: #f8f9fa;
        padding: 2rem;
        font-family: Arial, sans-serif;
    }

    .auth-title {
        font-size: 2rem;
        font-weight: bold;
        color: #2c3e50;
        margin-bottom: 2rem;
        text-align: center;
    }

    .auth-image {
        max-width: 300px;
        height: auto;
        margin: 1.5rem 0;
        filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
    }

    .auth-message {
        text-align: center;
        max-width: 500px;
        line-height: 1.6;
        color: #5a6c7d;
        background-color: white;
        border-radius: 8px;
        padding: 1.5rem;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

    .auth-message p {
        margin: 0.8rem 0;
    }

    .error-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        padding: 2rem;
        background-color: #fff5f5;
        text-align: center;
    }

    .error-container h1 {
        color: #e53e3e;
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    .home-link {
        margin-top: 1.5rem;
        color: #3182ce;
        text-decoration: none;
        font-weight: bold;
        border: 2px solid #3182ce;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        transition: all 0.3s ease;
    }

    .home-link:hover {
        background-color: #3182ce;
        color: white;
    }
`;
document.head.appendChild(styles);
