import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./Stylesheets/index.css";
import Profile from "./App/Profile";
import { Link, Navigate, BrowserRouter, Routes, Route, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import LoginForm from "./App/login.js";
import { Flex } from "antd";
import AdminPanel from "./Admin/AdminPanel.js";
import { AppProvider } from './App/AppContext';
import { HTMLRenderer, useRequestWithNavigate } from "./utils";
import HandleLaforgeLogin from "./App/LaforgeLogin";
import ReactJson from 'react-json-view';
import TestPage from "./TestPage";
import { MetricsProvider } from "./SiteMetricContext";
import { FrownOutlined } from "@ant-design/icons";
import { CourseListProvider } from "./DataVisualizer/CourseListContext.js";
import axios from "axios";
import { server_api_addr } from "./utils";

const ServerError = () => {
	const location = useLocation();

	let htmlCode = location.state?.htmlCode || '<h1>Unknown Error</h1><p>Something went wrong.</p>';
	const isJSON = typeof (htmlCode) === 'object';

	console.log("Error page", isJSON, htmlCode);

	return (
		<div>
			{
				isJSON ? (
					<div>
						<h1>Error Response:</h1>
						<ReactJson
							src={htmlCode}
							style={{ padding: '10px', backgroundColor: '#f5f5f5' }}
							theme="rjv-default"
							enableClipboard={false}
						/>
					</div>
				) : (
					<HTMLRenderer htmlCode={htmlCode} />
				)
			}
		</div>
	);
};

const NotFound = () => {
	const styles = {
		container: {
			textAlign: 'center',
			marginTop: '5vh',
		},
		header: {
			fontSize: '4rem',
			color: '#ff6f61',
		},
		message: {
			fontSize: '1.5rem',
			color: '#333',
			marginBottom: '2rem',
		},
		link: {
			fontSize: '1.2rem',
			color: '#007bff',
			textDecoration: 'none',
			border: '1px solid #007bff',
			padding: '0.5rem 1rem',
			borderRadius: '5px',
			transition: 'background-color 0.3s, color 0.3s',
		},
		linkHover: {
			backgroundColor: '#007bff',
			color: '#fff',
		},
	};

	const [isHovered, setIsHovered] = useState(false);

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	return (
		<div style={styles.container}>
			<img
				src="https://media.licdn.com/dms/image/v2/C5112AQFmZ4W3X22MnQ/article-cover_image-shrink_600_2000/article-cover_image-shrink_600_2000/0/1562261391336?e=2147483647&v=beta&t=bNVxCapOXap2aidrP82loQDyhX_xfS1gxYKwzsJWTYk"
				alt="Not Found"
				style={{ width: '100%', maxWidth: '600px', marginBottom: '2rem' }}
			/>
			<h1 style={styles.header}>404 - Page Not Found</h1>
			<p style={styles.message}>Oops! The page you are looking for does not exist.</p>
			<Link
				to="/"
				style={isHovered ? { ...styles.link, ...styles.linkHover } : styles.link}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				Go back to the home page
			</Link>
		</div>
	);
};

const entry = '/dashboard';
// const entry = '/auth';

const Root = () => {
	const [authenticated, setAuthenticated] = useState(false);
	const [targetUser, setTargetUser] = useState('');
	const location = useLocation(); // React Router's hook to get current location
	const pathname = location.pathname;
	const navigate = useNavigate();
	// const request = useRequestWithNavigate();

	const PrivateRoute = ({ auth: { isAuthenticated }, children }) => {
		return isAuthenticated ? children : <Navigate to="/" />;
	};

	useEffect(() => {
		console.log("process.env.REACT_APP_PRODUCTION", process.env.REACT_APP_PRODUCTION)

		if (!localStorage.getItem('terms')) {
			localStorage.setItem('terms', JSON.stringify([]));
		}

		if (process.env.REACT_APP_PRODUCTION !== "1") {
			let token = JSON.parse(localStorage.getItem('jwt'));

			if (token.access) {
				console.log("Redirect")
				setAuthenticated(true);
				setTargetUser('chendeme');
				navigate(entry);
			}
		} else {
			// Send the stored jwt to the backend to check if the user is authenticated, if so, then get the utorid
			// TODO: Implement this in the backend
			const tokens = JSON.parse(localStorage.getItem('jwt'));
			const accessToken = tokens?.access;
			let headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : {};

			axios.post(`${server_api_addr}auth/get_utorid`, {}, {
				headers,
			}).then((response) => {
				console.log("response", response.data);
				if (response.data.utorid) {
					setAuthenticated(true);
					setTargetUser(response.data.utorid);
					navigate(entry);
				} else {
					// Refresh token
				}
			}).catch((error) => {
				console.error("Error checking auth", error);
				// navigate("/")
				// navigate('/', { state: { htmlCode: error.response.data } });
			});
		}
	}, [])

	useEffect(() => {
		if (process.env.REACT_APP_PRODUCTION !== "1") {
			if (authenticated) {
				navigate(entry);
			}
			console.log(authenticated);
		}
	}, [authenticated]);

	return (
		<Routes>
			<Route
				path="/"
				element={
					<Flex justify="center" style={{ height: '100vh' }}>
						<LoginForm />
					</Flex>
				}
			/>

			<Route
				path="/admin"
				element={
					<AppProvider admin={true}>
						<CourseListProvider>
							<AdminPanel></AdminPanel>
						</CourseListProvider>
					</AppProvider>
				}
			/>

			<Route
				path='/dashboard'
				element={
					<PrivateRoute auth={{ isAuthenticated: authenticated }}>
						<React.StrictMode>
							<MetricsProvider>
								<AppProvider target_user={targetUser}>
									<CourseListProvider>
										<Profile setAuthenticated={setAuthenticated} />
									</CourseListProvider>
								</AppProvider>
							</MetricsProvider>
						</React.StrictMode>
					</PrivateRoute>
				}
			/>

			<Route path="/error" element={<ServerError />} />

			{/* LaForge OAuth redirection handler */}
			<Route
				path="/auth"
				element={<HandleLaforgeLogin setTargetUser={setTargetUser} setAuthenticated={setAuthenticated} />}
			/>

			<Route path="/test" element={<TestPage />} />

			{/* Catch-all route for 404 */}
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<BrowserRouter>
		<Root />
	</BrowserRouter>
);
