import { Button, Col, message, Popover } from "antd";
import { getCourseColor, alphanumerical, findCourseLocation, requestCourseDetails, calculateDisplayCourseCardWidth, useRequestWithNavigate, processCourseDetails} from "../utils";
import { useAppContext } from "../App/AppContext";
import useCourseActions from "./courseActions";
import { CourseListContext } from "./CourseListContext";
import { useContext, useState } from "react";

const DisplayCourseCard = ({course, index, width=130, minWidth=130}) => {
    const { setCurrentCourseDetails, setCourseInfoModalOpen, formattedCourseData, draggingCard} = useAppContext();
    
    const {courseActionHistory, courseActionStep} = useContext(CourseListContext);

    const { addCourse, checkCourseLegalBasic, moveCourse, handleCourseCardDrop } = useCourseActions(courseActionStep, courseActionHistory);

    const request = useRequestWithNavigate();

    let violation = null;
    let violation_level = 0;

    // const course_card_width = 130;
    const course_card_width = Math.max(width, minWidth);
    const course_card_height = course_card_width * 6 / 13;
    
    // const course_code_fontsize = `${Math.ceil(course_card_width/12)}pt`; // 11pt
    // const course_name_fontsize = `${Math.ceil(course_card_width/19)}pt`; // 7pt
    const course_code_fontsize = `${course_card_width / 11.5}pt`;
    const course_name_fontsize = `${course_card_width / 19}pt`;

    function showCourseInfoModal(course_info) {
        request('get_course', {
            code: course_info["code"]
        }).then((data)=>{
            let course_details = processCourseDetails(data);
            console.log("Got course detail:", course_details);
            setCurrentCourseDetails(course_details);
            setCourseInfoModalOpen(true);
        }).catch((error) => {
            message.error('Failed to get course details.');
            if (error.response) {
                console.log(error.response.data);
                alert(JSON.stringify(error.response.data));
            } else {
                console.error("Error", error);
            }
        });
    }
    
    const handleCourseCardDragStart = (term, code) => {
        const [term_row_index, term_course_index] = findCourseLocation(formattedCourseData, term, code);
        console.log(`Dragging course at: ${term_row_index}, ${term_course_index}`);
        draggingCard.current = { term_row_index, term_course_index, new: false, source: 'index' };
    };

    const handleCourseCardMarginDrop = (term, index, target) => {
        if (draggingCard.current) {
            console.log("Drop on margin", term, index);

            // Dropping on empty slot
            var term_row_index = findCourseLocation(formattedCourseData, term, null);
            var term_course_index = index;

            const newData = [...formattedCourseData];
            let dragCardRow = null;
            let dragCardCourse = null;
            let draggedCard = null;

            if (draggingCard.current.new) {
                console.log("handle adding new course")
                draggedCard = draggingCard.current.payload;
            } else {
                dragCardRow = draggingCard.current.term_row_index;
                dragCardCourse = draggingCard.current.term_course_index;
                draggedCard = newData[dragCardRow].term_courses[dragCardCourse];
            }

            if (!checkCourseLegalBasic(draggedCard, term)) {
                target.classList.remove("courseCardMarginLeftHover");
                return;
            }
            
            if (draggingCard.current.new) {
                addCourse(draggedCard, term_row_index, term_course_index);
            } else {
                moveCourse(dragCardRow, dragCardCourse, term_row_index, term_course_index);
            }
        }
        // Remove styling of margin drop hover effect
        target.classList.remove("courseCardMarginLeftHover");
    };

    // console.log(`Hey, I'm ${course.code}, I got rerendered`);
    if(course['violation'] && Object.keys(course['violation']).length !== 0){
        if(Object.keys(course['violation']['prerequistes']).length !== 0){
            violation = course['violation'];
            
            violation_level = 1;
            
            if(violation['prerequistes']['violated']) {
                if(violation['prerequistes']['violated'].length > 0){
                    violation_level += 1;
                }
            }
        }
    }

    return (
        <Col
            key={`${course["term"]}-${course["code"]}`}
            // key={alphanumerical()}
            style={{ 
                padding: 0, 
                display: "flex", 
                // transition: "all 0.5s ease-in-out"
            }}
        >
            {
                violation && (
                    // draw a red dot
                    <Popover
                        content={
                            // <Button>Fix It For Me</Button>
                            "Try asking the assistant about it."
                        }
                        title={"Requisite Violation Detected"}
                        trigger="hover"
                        overlayStyle={{
                            width: "250px",
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",
                        }}
                        key={`Violation-${course['code']}-${course['term']}`}
                    >
                        <div
                            style={{
                                position: "absolute",
                                top: 3,
                                right: 3,
                                width: "10px",
                                height: "10px",
                                borderRadius: "50%",
                                backgroundColor: `hsl(${(50 - (violation_level - 1) * 50)} 100% 50%)`,
                                zIndex: 2,
                            }}
                        />               
                    </Popover>
                )
            }
            {course["code"] !== null && (
                <div
                    className="courseCardMarginLeft"
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(event) => {
                        event.stopPropagation();
                        handleCourseCardMarginDrop(course["term"], index, event["target"]);
                    }}
                    onDragEnter={(event) => {
                        event["target"].classList.add("courseCardMarginLeftHover");
                    }}
                    onDragLeave={(event) => {
                        event["target"].classList.remove("courseCardMarginLeftHover");
                    }}
                />
            )}

            <div
                style={{
                    // width: '5vw',
                    width: course_card_width,
                    height: course_card_height,
                    color: getCourseColor(course["status"]),
                    paddingLeft: '3px',
                    paddingRight: '3px',
                }}
                className={`CourseCardFamily CourseCard`}
                onClick={() =>
                    course["code"] !== null &&
                    showCourseInfoModal({
                        code: course["code"],
                        term: course["term"],
                    })
                }
                draggable
                onDragStart={() => handleCourseCardDragStart(course["term"], course["code"])}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                    e.stopPropagation();
                    handleCourseCardDrop(course["term"], course["code"])
                }}
            >
                <span
                    style={{
                        fontSize: course_code_fontsize,
                        fontFamily: "arial",
                        textDecoration: "none",
                        color: getCourseColor(course["status"]),
                        fontWeight: "bold",
                    }}
                    className="CourseCardFamily CourseCardCode"
                >
                    {course["code"]}
                </span>

                <div
                    style={{
                        fontSize: course_name_fontsize,
                        fontFamily: "arial",
                        lineHeight: "1",
                        cursor: "pointer",
                    }}
                    className="CourseCardFamily CourseCardName"
                >
                    {course["name"]}
                </div>
            </div>
        </Col>
    )
}

export default DisplayCourseCard;