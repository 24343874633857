import { Col, Checkbox, message } from "antd";
import { CloseOutlined, MinusOutlined } from "@ant-design/icons";
import { processCourseDetails, alphanumerical, useRequestWithNavigate } from "../utils";
import { useAppContext } from "../App/AppContext";
import { defaultFilterData } from "../utils";

const GenericCourseCardSmall = ({ code, status, source = 'index', term = null, clickable = true, className = null, course_info = null, width = "105px", draggable = false, style = null }) => {
    const { setCurrentCourseDetails, setCourseInfoModalOpen, formattedCourseData, setFormattedCourseData, draggingCard, courseActionStep } = useAppContext();
    const request = useRequestWithNavigate();

    function showCourseInfoModal(course_info) {
        // code, section, year
        // console.log('course_info["code"]: ', course_info, course_info["code"])
        let courseCode = course_info?.code || code;
        console.log('Generic course click:', courseCode);

        if (courseCode.split(' ').length === 1) {
            if (!course_info) {
                setCourseInfoModalOpen(true);
                return request("search_courses", {
                    prefix: courseCode,
                    ...defaultFilterData
                }).then((course_data) => {
                    if(course_data.length === 0){
                        message.error("Requested course does not exist.")
                        return;
                    }
                    let data = course_data[0];
                    console.log("Got data", course_data);
                    let course_details = processCourseDetails(data);
                    console.log("Got course detail:", course_details);
                    setCurrentCourseDetails(course_details);
                    // setCourseInfoModalOpen(true);
                })
            } else {
                if (courseCode.match(/Y\d/)) {
                    courseCode = `${courseCode} Y`;
                } else {
                    courseCode = `${courseCode} ${course_info['fall'] || course_info['twin'] ? 'F' : 'S'}`
                }
            }
        }

        request('get_course', {
            code: courseCode
        }).then((data) => {
            let course_details = processCourseDetails(data);
            console.log("Got course detail:", course_details);
            setCurrentCourseDetails(course_details);
            setCourseInfoModalOpen(true);
        }).catch((error) => {
            message.error('Failed to get course details.');
            if (error.response) {
                console.log(error.response.data);
                alert(JSON.stringify(error.response.data));
            } else {
                console.error("Error", error);
            }
        });
    }

    const handleCourseCardDragStart = async (code, course_info) => {
        console.log("Dragging generic course", code, course_info)

        draggingCard.current = {
            term_row_index: -1,
            term_course_index: -1,
            new: true,
            payload: course_info,
            source: source
        };
    };

    return (
        <Col
            className={`lowerProgramRequirementSmallCard ${className}`}
            style={{
                cursor: clickable ? "pointer" : "default",
                marginLeft: "5px",
                width: width,
                ...style
            }}
            // Finish on drag event for generic course card
            draggable={draggable}
            onDragStart={() => handleCourseCardDragStart(code, course_info)}
            onDragOver={(e) => e.preventDefault()}
            onDrop={() => {
                message.error("Can't perform this action!");
                // handleCourseCardDrop(term, code)
            }}

            onClick={() => {
                if (clickable) {
                    showCourseInfoModal(course_info);
                }
            }}
            key={alphanumerical()}
        >
            <span>{code}</span>

            {status === 0 && <Checkbox checked={true} className="lowerProgramRequirementSmallCardCheckBox" id={`${code}-${status}-${source}-${term}-checkbox`}/>}
            {status === 1 && (
                <MinusOutlined
                    style={{
                        border: "1px solid orange",
                        borderRadius: "4px",
                        backgroundColor: "orange",
                        color: "white",
                        fontWeight: "bold",
                        marginLeft: "5px",
                    }}
                />
            )}
            {status === 2 && (
                <CloseOutlined
                    style={{
                        border: "1px solid red",
                        borderRadius: "4px",
                        backgroundColor: "red",
                        color: "white",
                        fontWeight: "bold",
                    }}
                />
            )}
        </Col>
    );
};

export default GenericCourseCardSmall;