import React from "react";

// filepath: /Users/shawuxi/Desktop/UofT/capstone/Modern-AI-Magellan/frontend/src/App/LoadingProfile.js

const LoadingProfile = ({ message = "Loading your profile..." }) => {
    return (
        <div className="loading-container">
            <h2 className="loading-title">Loading Dashboard</h2>

            <img 
                className="loading-image animate-robot"
                src={`${process.env.PUBLIC_URL}/cat_moving.png`} 
                alt="Robot working"
            />

            <div className="loading-message">
                <p>{message}</p>
                <p>Please wait for a second while we fetch all your data...</p>
                <p style={{fontWeight: 'bold'}}>If this is your first time logging in, it may take an extra second.</p>
            </div>
        </div>
    );
};

export default LoadingProfile;

// Add styles
const styles = document.createElement('style');
styles.textContent = `
    .loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100vh;
        background-color: #f8f9fa;
        padding: 2rem;
        font-family: Arial, sans-serif;
    }

    .loading-title {
        font-size: 2rem;
        font-weight: bold;
        color: #2c3e50;
        margin-bottom: 2rem;
        text-align: center;
    }

    .loading-image {
        max-width: 300px;
        height: auto;
        margin: 1.5rem 0;
        filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
    }

    .animate-robot {
        animation: moveLeftRight 4s infinite ease-in-out;
    }

    @keyframes moveLeftRight {
        0% { 
            transform: translateX(-200px) scaleX(-1); 
        }
        25% { 
            transform: translateX(0px) scaleX(-1); 
        }
        50% { 
            transform: translateX(200px) scaleX(1); 
        }
        75% { 
            transform: translateX(0px) scaleX(1); 
        }
        100% { 
            transform: translateX(-200px) scaleX(-1); 
        }
    }

    .loading-message {
        text-align: center;
        max-width: 500px;
        line-height: 1.6;
        color: #5a6c7d;
        background-color: white;
        border-radius: 8px;
        padding: 1.5rem;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

    .loading-message p {
        margin: 0.8rem 0;
    }
`;
document.head.appendChild(styles);